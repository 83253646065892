import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ButtonMui from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

import { wesperLogo } from 'src/assets/images';
import { Input, PasswordInput, Toast } from 'src/components';
import { userActions } from 'src/store/actions';
import { loginRequest, resetPasswordRequest } from 'src/utils/auth';
import { colors } from 'src/utils/colors';
import { jwtStorageName, userRoles } from 'src/utils/constants';
import { getUserInfoFromToken } from 'src/utils/jwtToken';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Card = styled.div`
  border: 1px solid gray;
  border-radius: 5px;
  min-width: 300px;
  width: 40%;
  max-width: 350px;
  padding: 50px 30px;
`;

const Form = styled.form`
  width: 100%;
`;

const Button = styled(ButtonMui)`
  width: 90%;
  background: #ffffff;
  margin-top: 40px;
  min-height: 50px;
  font-size: 1rem;
  letter-spacing: 3px;
  &:hover {
    background: #f0f0f0;
  }
  color: ${colors.darkGray};
`;

const Title = styled.h1`
  font-weight: 300;
  letter-spacing: 5px;
  font-size: 1.4rem;
`;

const Loading = styled(CircularProgress)`
  color: #000000;
  margin-top: 50px;
`;

const ForgotPasswordLink = styled.p`
  margin-top: 8%;
  margin-bottom: 0;
  text-decoration: none;
  color: ${colors.blue};
  font-size: 0.75rem;
  cursor: pointer;
  &:hover {
    color: ${colors.darkBlue};
  }
`;

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const dispatch: any = useDispatch();

  const showErrorMessage = (error: string) => {
    const message = error || 'Invalid Email or Password';
    console.log('--- error');
    console.log(error);
    toast.error(message, { autoClose: 15000 });
  };

  const onLoginRequest = async () => {
    try {
      setLoading(true);
      const response = await loginRequest(email, password);
      const token = response.data.token;
      localStorage.setItem(jwtStorageName, token);
      const userInfo = getUserInfoFromToken(token);

      // If user does not have admin or hardware-diagnostics, throw this error
      if (
        !userInfo.groups.includes(userRoles.admin)
        && !userInfo.groups.includes(userRoles.hardware)
        && !userInfo.groups.includes(userRoles.manufacturer)
      ) throw 'User can not access this resource';

      dispatch(userActions.setCurrentUser(userInfo));
    } catch (error: any) {
      const errorMessage = error?.response?.data?.description ?? 'Invalid Email or Password';
      showErrorMessage(errorMessage);
      setLoading(false);
    }
  };

  const submitLoginForm = (event: any) => {
    event.preventDefault();
    onLoginRequest();
  };

  const onResetPasswordRequest = async () => {
    try {
      setLoading(true);
      await resetPasswordRequest(email);
      setLoading(false);
      setEmail('');
      setShowResetPassword(false);
      toast.success(
        'Your request was received, please check your email for more instructions.',
        { autoClose: false },
      );
    } catch (error) {
      setLoading(false);
      toast.error('Reset password failed. Please try again later', { autoClose: 15000 });

    }
  };

  const submitResetPasswordForm = (event: any) => {
    event.preventDefault();
    onResetPasswordRequest();
  };

  return (
    <Container>
      <Toast />
      <Card>
        <img src={wesperLogo} alt="tatch-logo" width="220" />
        <Title>ADMIN PORTAL</Title>
        {showResetPassword ? (
          <Form onSubmit={submitResetPasswordForm}>
            <Input
              autoComplete="off"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(event) => setEmail(event?.target?.value)}
            />
            { loading
              ? <div style={{ width: '100%' }}><Loading /></div>
              : <>
                <Button type="submit" variant="contained" size="large">Reset Password</Button>
                <ForgotPasswordLink onClick={() => setShowResetPassword(false)}>
                  Cancel
                </ForgotPasswordLink>
              </>
            }
          </Form>
        ) : (
          <Form onSubmit={submitLoginForm}>
            <Input
              autoComplete="off"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(event) => setEmail(event?.target?.value)}
            />
            <PasswordInput
              password={password}
              label="Password"
              onPasswordChange={(value: string) => setPassword(value)}
            />

            { loading
              ? <div style={{ width: '100%' }}><Loading /></div>
              : <>
                <Button type="submit" variant="contained" size="large">Login</Button>
                <ForgotPasswordLink onClick={() => setShowResetPassword(true)}>
                  Forgot your password? Click here to reset it.
                </ForgotPasswordLink>
              </>
            }
          </Form>
        )}
      </Card>
    </Container>
  );
};

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton/IconButton';
import MenuMui from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';

import { logoutIcon, resetPasswordIcon, settingsIcon } from 'src/assets/images';
import { userActions } from 'src/store/actions';
import { resetPasswordRequest } from 'src/utils/auth';
import { colors } from 'src/utils/colors';
import { jwtStorageName } from 'src/utils/constants';

const LogoutIcon = styled.img`
  width: 20px;
  margin-right: 5px;
`;

const ResetPasswordIcon = styled.img`
  width: 22px;
  margin-right: 5px;
`;

const Menu = styled(MenuMui)`
  .MuiPaper-root {
    background-color: #ffffff;
  }
  .MuiPopover-paper {
    min-width: 150px;
  }
`;

const Loading = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
  color: ${colors.darkGray};
`;

export const SettingsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeSettingsMenu = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    try {
      localStorage.setItem(jwtStorageName, '');
      dispatch(userActions.logout());
      navigate('/');
    } catch (error) {
      toast.error('Logout failed', { autoClose: 15000 });
    }
  };

  const resetPassword = async () => {
    try {
      setResetPasswordLoading(true);
      await resetPasswordRequest();
      setResetPasswordLoading(false);
      await logout();
      toast.success(
        'Your requested was received, please check your email for more instructions.',
        { autoClose: false },
      );
    } catch (error) {
      setResetPasswordLoading(false);
      toast.error('Reset password failed. Please try again later', { autoClose: 15000 });

    }
  };

  return <>
    <IconButton
      aria-label="settings-btn"
      aria-haspopup="true"
      onClick={handleClick}
      size="large">
      <img src={settingsIcon} alt="settings icon" width="23" />
    </IconButton>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={closeSettingsMenu}
      // getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <MenuItem onClick={logout}>
        <LogoutIcon src={logoutIcon} alt="logout icon" />
        Logout
      </MenuItem>
      <MenuItem onClick={resetPassword}>
        {resetPasswordLoading ?
          <Loading /> :
          <ResetPasswordIcon src={resetPasswordIcon} alt="reset password icon" />
        }
        Reset Password
      </MenuItem>
    </Menu>
  </>;
};

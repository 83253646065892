import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

import { eyeCloseIcon, eyeOpenIcon } from 'src/assets/images';
import { colors } from 'src/utils/colors';

const Input = styled(TextField)`
  width: 90%;
  margin-top: 20px !important;
  & label.Mui-focused {
    color: ${colors.darkGray};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${colors.darkGray};
    }
  }
`;

type Props = {
  password: string;
  label: string;
  onPasswordChange: (value: any) => void;
}

export const PasswordInput = ({ password, label, onPasswordChange }: Props) => {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <Input
      autoComplete="off"
      type={hidePassword ? 'password' : 'text'}
      label={label}
      variant="outlined"
      value={password}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onPasswordChange(event?.target?.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setHidePassword(!hidePassword)}
              size="large">
              {hidePassword
                ? <img src={eyeCloseIcon} alt="eye close icon" width="24" />
                : <img src={eyeOpenIcon} alt="eye open icon" width="24" />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { PatchFormProps } from 'src/types';
import { axiosPost } from 'src/utils/apiRequests';
import { API_PATHS } from 'src/utils/constants';

type KitGroupFormProps = {
  serialNumber: string;
  resetFlag: boolean;
  formattedFlag: boolean;
  groupedAt: string;
  patches: PatchFormProps[];
}

const sendKitGroupRequest = async (props: KitGroupFormProps) => {
  const link = `${process.env.REACT_APP_API}${API_PATHS.KIT_GROUPS}`;
  const response = await axiosPost({ link, params: props });

  return response;
};

export const useMutationKitForm = () => {
  const {
    data,
    mutate: createKitGroup,
    isLoading,
    isSuccess,
  } = useMutation(
    'create-kit-group',
    (props: KitGroupFormProps) => sendKitGroupRequest(props),
    {
      onSuccess: () => {
        toast.success('Kit Group was created!', { autoClose: 5000 });
      },
      onError: (error: any) => {
        console.log('Error: ', error);
        toast.error('KitGroup creation failed', { autoClose: 7000 });
      },
    }
  );

  return {
    data,
    createKitGroup,
    isLoading,
    isSuccess,
  };
};

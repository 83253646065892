import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import './App.css';

import { Header, Routes, Toast } from 'src/components';
import { useTypedSelector } from 'src/hooks';
import { Login } from 'src/pages';
import { userActions } from 'src/store/actions';
import { jwtStorageName, userRoles } from 'src/utils/constants';
import { getUserInfoFromToken, isTokenValid } from 'src/utils/jwtToken';

const App = () => {
  const dispatch: any = useDispatch();
  const { currentUser, checkedLoginOnLoad } = useTypedSelector(({ user }) => user);

  useEffect(() => {
    try {
      const token = localStorage.getItem(jwtStorageName);
      if (!token || !isTokenValid(token)) {
        dispatch(userActions.setCheckedLogin(true));

        return;
      }

      const userInfo = getUserInfoFromToken(token);

      // If user does not have admin group, hardware-diagnostics or manufacturer group, throw this error
      if (
        !userInfo.groups.includes(userRoles.admin)
        && !userInfo.groups.includes(userRoles.hardware)
        && !userInfo.groups.includes(userRoles.manufacturer)
      ) {
        dispatch(userActions.setCheckedLogin(true));
        throw 'User can not access this resource';
      }

      dispatch(userActions.setCurrentUser(userInfo));
    } catch (error: any) {
      toast.error(error, { autoClose: 15000 });
    }
  }, [dispatch]);

  if (!currentUser && !checkedLoginOnLoad) {
    return <h1>Loading ...</h1>;
  }

  if (checkedLoginOnLoad && !currentUser) {
    return (
      <Login />
    );
  }

  return (
    <div className="App">
      <Toast />
      <Header />
      <Routes />
    </div>
  );
};

export default App;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* Most of this file is either exactly copied from various Web Bluetooth API examples,
 * copied from those examples with slight modifications, or is additional code to
 * make those examples play nicely with react.
 *
 * Author: Conor
 */

// some stuff that chrome exposes from native code but that react
// can't compile. I'm just doing this as a workaround until something
// better appears.
const serviceNameToUUID: any = {
  manufacturer_name_string: '00002a29-0000-1000-8000-00805f9b34fb',
  model_number_string: '00002a24-0000-1000-8000-00805f9b34fb',
  hardware_revision_string: '00002a27-0000-1000-8000-00805f9b34fb',
  firmware_revision_string: '00002a26-0000-1000-8000-00805f9b34fb',
  software_revision_string: '00002a28-0000-1000-8000-00805f9b34fb',
  system_id: '00002a23-0000-1000-8000-00805f9b34fb',
  'ieee_11073-20601_regulatory_certification_data_list': '00002a2a-0000-1000-8000-00805f9b34fb',
  pnp_id: '00002a50-0000-1000-8000-00805f9b34fb',
};

function getCharacteristic(name: any) {
  return serviceNameToUUID[name];
}


// this is actually defined in chrome, but react can't detect it.
const valueToUsbVendorName: any = {};

/* Utils */

function padHex(value: any) {
  return ('00' + value.toString(16).toUpperCase()).slice(-2);
}

function getUsbVendorName(value: any) {
// Check out page source to see what valueToUsbVendorName object is.
  return value +
    (value in valueToUsbVendorName ? ' (' + valueToUsbVendorName[value] + ')' : '');
}

interface SystemId {
  manufacturerId: string;
  orgId: string;
}

export interface DeviceInfo {
  manufacturerName?: string;
  modelNumber?: string;
  modelNumberBinary?: string;
  hardwareRevision?: string;
  firmwareRevision?: string;
  softwareRevision?: string;
  systemId?: SystemId;
  ieeeCertificationData?: string;
  unknownCharacteristics?: string[];
}

export function getDeviceInfo(server: BluetoothRemoteGATTServer): Promise<DeviceInfo> {
  console.log('Getting Device Information Service...');
  let output = server.getPrimaryService('device_information')
    .then((service: BluetoothRemoteGATTService) => {
      console.log('Getting Device Information Characteristics...');

      return service.getCharacteristics();
    })
    .then((characteristics: BluetoothRemoteGATTCharacteristic[]) => {
      let queue = Promise.resolve();
      let decoder = new TextDecoder('utf-8');
      let info: DeviceInfo = {};
      let line = '';
      characteristics.forEach((characteristic: any) => {
        switch (characteristic.uuid) {

        case getCharacteristic('manufacturer_name_string'):
          queue = queue.then(_ => characteristic.readValue()).then(value => {
            info.manufacturerName = decoder.decode(value);
          });
          break;

        case getCharacteristic('model_number_string'):
          queue = queue.then(_ => characteristic.readValue()).then((value: DataView) => {
            info.modelNumber = decoder.decode(value);
            info.modelNumberBinary = buf2hex(value.buffer);
          });
          break;

        case getCharacteristic('hardware_revision_string'):
          queue = queue.then(_ => characteristic.readValue()).then(value => {
            info.hardwareRevision = decoder.decode(value);
          });
          break;

        case getCharacteristic('firmware_revision_string'):
          queue = queue.then(_ => characteristic.readValue()).then(value => {
            info.firmwareRevision = decoder.decode(value);
          });
          break;

        case getCharacteristic('software_revision_string'):
          queue = queue.then(_ => characteristic.readValue()).then(value => {
            info.softwareRevision = decoder.decode(value);
          });
          break;

        case getCharacteristic('system_id'):
          queue = queue.then(_ => characteristic.readValue()).then(value => {
            info.systemId = {
              manufacturerId: padHex(value.getUint8(4)) + padHex(value.getUint8(3)) +
                padHex(value.getUint8(2)) + padHex(value.getUint8(1)) +
                padHex(value.getUint8(0)),
              orgId: padHex(value.getUint8(7)) + padHex(value.getUint8(6)) +
                padHex(value.getUint8(5)),
            };
          });
          break;

        case getCharacteristic('ieee_11073-20601_regulatory_certification_data_list'):
          queue = queue.then(_ => characteristic.readValue()).then(value => {
            info.ieeeCertificationData = decoder.decode(value);
          });
          break;

          // no pnp id handling

        default:
          if (!('unknownCharacteristics' in info)) {
            info.unknownCharacteristics = [];
          }
          info.unknownCharacteristics?.push(characteristic.uuid);
        }
      });

      return queue.then(_ => {return info});
    });

  return output;
}

/**
 *
 * @param server ble server
 * @param serviceUuid resolvable uuid for the service containing this characteristic
 * @param characteristicUuid resolvable uuid for the characteristic
 * @returns the characteristic instance
 */
export async function getServiceCharacteristic(server: Promise<BluetoothRemoteGATTServer>,
  serviceUuid: string | number, characteristicUuid: string | number): Promise<BluetoothRemoteGATTCharacteristic> {
  const realServer = await server;
  const service = await realServer.getPrimaryService(serviceUuid);

  return service.getCharacteristic(characteristicUuid);
}

/**
 * @param flags a bitfield
 * @param bitNumber bit number to decode as a flag
 * @returns true or false depending on flag value
 */
export function decodeBitFlag(flags: number, bitNumber: number): boolean {
  return (flags & (1 << bitNumber)) != 0;
}

/**
* Gets the current unix timestamp in ms as a 32-bit Uint8Array
*
* @return     {Uint8Array}  4-byte array of epoch time in ms
*/
export function nowMsUint8() {
  const ts = new Uint32Array(1);
  ts[0] = Date.now();

  const result: any = new Uint8Array(ts.buffer);

  return result;
}

export function logHex(buffer: any, msg = '') {
  let str = '';
  if (buffer === undefined) {
    str = 'undefined';
  }
  // duck typing detection for dataview
  else if ('buffer' in buffer) {
    str = buf2hex(buffer.buffer);
  }
  // assume it's an ArrayBuffer
  else {
    str = buf2hex(buffer);
  }
  console.log(msg + ' (' + str + ')');
}

export function buf2hex(buffer: ArrayBuffer): string {
  return Array.prototype.map.call(
    new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2),
  ).join(' ').toUpperCase();
}

/* Utils */

// the below is adapted from chrome's web bluetooth example here:
// https://googlechrome.github.io/samples/web-bluetooth/automatic-reconnect-async-await.html

// This function keeps calling "toTry" until promise resolves or has
// retried "max" number of times. First retry has a delay of "delay" seconds.
// "success" is called upon success.
export async function exponentialBackoff(
  max: number, delay: number,
  toTry: () => Promise<BluetoothRemoteGATTServer | undefined>,
  success: (server: BluetoothRemoteGATTServer | undefined) => void,
  fail: () => void)
{
  try {
    const result = await toTry();
    success(result);
  } catch(error) {
    if (max === 0) {
      return fail();
    }
    time('Retrying in ' + delay + 's... (' + max + ' tries left)');
    setTimeout(function() {
      exponentialBackoff(--max, delay * 2, toTry, success, fail);
    }, delay * 1000);
  }
}

export function time(text: string) {
  console.log('[' + new Date().toJSON().substr(11, 8) + '] ' + text);
}

import { combineReducers } from 'redux';

import sessionsReducer from './modules/sessionsSlice';
import userReducer from './modules/userSlice';

const reducers = {
  user: userReducer,
  sessions: sessionsReducer,
};

const appReducer = combineReducers<any, any>(reducers);

export type RootState = ReturnType<typeof appReducer>;

// When the state gets bigger
// const rootReducer = (state: RootState | undefined, action: any) => {
//   if (action.type === userActions.logout.type) {
//     // Logout action must clear redux store
//     // solution from Dan Abramov https://stackoverflow.com/a/35641992/7390929
//     state = undefined;
//     state.user = initialState;
//   }

//   return appReducer(state, action);
// };

export default appReducer;

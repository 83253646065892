import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentMui from '@mui/material/DialogContent';
import DividerMui from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import PaperMui from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import { refreshIcon, xIcon } from 'src/assets/images';
import { ErrorCard } from 'src/components/ErrorCard';
import { ExpandableList } from 'src/components/ExpandableList';
import { useTypedSelector } from 'src/hooks';
import {
  getSessionDetails,
  postReprocess,
  sessionsActions,
} from 'src/store/modules/sessionsSlice';
import {
  EventLog,
  FileItem,
  Session,
  SessionDetailsResponse,
} from 'src/types';
import { randomString } from 'src/utils/common';
import { loadStatus } from 'src/utils/constants';
import { formatMysqlDate } from 'src/utils/date';
import { formatToDecimalNumbers } from 'src/utils/formatters';
import { getPatchOrder, getStatusColor } from 'src/utils/sessions';

const DialogContent = styled(DialogContentMui)`
  padding: 0px 30px 30px;
`;

const Divider = styled(DividerMui)`
  margin-bottom: 10px;
`;

// const TimeDivider = styled(DividerMui)`
//   height: 20px;
//   margin: 0 10px;
//   background: #999;
// `;

const SessionKey = styled.h6`
  margin: 10px 0 5px;
  font-size: 0.85rem;
`;

const SessionValue = styled.p`
  margin: 0;
  font-size: 1rem;
`;

const Paper = styled(PaperMui)`
  padding: 8px 10px;
`;

const EventTitle = styled.h4`
  margin-top: 0;
  margin-bottom: 10px;
`;

const EventContent = styled.p`
  margin: 0px;
`;

const Caption = styled.p`
  font-size: 0.9rem;
  color: gray;
  margin: 0px;
`;

const EventsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RefreshBtn = styled(IconButton)`
  margin-right: 5px;
`;

type Props = {
  session: Session | null;
  open: boolean;
  setOpen: any;
};


const getValidityIcon = (isValid?: boolean) => {
  if (isValid === true) {
    return '✅';
  }
  if (isValid === false) {
    return '❌';
  }

  return 'Unknown';
};

const parseFileItemString = (itemString: string): FileItem => {
  /**
 * Parses a string representation of a file item and returns a FileItem object.
 *
 * @param {string} itemString - The string representation of the file item. 
 * format : name:<name>,size:<size>,lastModified:<lastModified>
 * @return {FileItem} - The parsed FileItem object.
 */
  const parts = itemString.split(', ');

  const name = parts[0].substring(parts[0].indexOf(': ') + 1);
  const size = parts[1].substring(parts[1].indexOf(': ') + 1);
  const lastModified = parts[2].substring(parts[2].indexOf(': ') + 2);

  return { name, size, lastModified };
};

const getMissingFiles = (lastIndex: number, numberOfFiles: number) => {
  if (lastIndex === -1) return 'No files found';
  if (lastIndex === 0) return numberOfFiles;
  if (numberOfFiles === 0) return lastIndex;

  return lastIndex + 1 - numberOfFiles;
};

const renamePatch = (patchName: string) => {
  patchName = patchName.toUpperCase();
  if (patchName == 'THX') {
    return 'Thorax Patch';
  }
  if (patchName == 'ABD') {
    return 'Abdomen Patch';
  }
  if (patchName == 'FIN') {
    return 'Pulse Oximeter';
  }
  if (patchName == 'LLG') {
    return 'Left Leg Patch';
  }
  if (patchName == 'RLG') {
    return 'Right Leg Patch';
  }

  return '';
};

const formatLastIndex = (lastIndex: number) => {
  /**
   * Formats the last index received in the session, if it is -1 that means no files were found
   *
   * @param {number} lastIndex - The last index.
   * @return {number|string} - The formatted last index or "No files found".
   */
  if (lastIndex === -1) {
    return 'No files found';
  }
  if (lastIndex === 0) {
    return '1';
  }

  return lastIndex + 1;
};

export const SessionModal = ({ session, open, setOpen }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [sessionDetails, setSessionDetails] =
    useState<SessionDetailsResponse | null>(null);

  const {
    eventsRequestStatus,
    selectedSessionEvents: eventsLogs,
    eventsError,
  } = useTypedSelector(({ sessions }) => sessions);
  const dispatch: any = useDispatch();

  const handleGetSessionDetails = (session: Session) => {
    setSessionDetails(null);
    getSessionDetails({ sessionId: session.id })
      .then((data) => {
        setSessionDetails(data);
      })
      .catch(() => {
        toast.error('Could not get session details', { autoClose: 3000 });
      });
  };

  const loadEvents = async () => {
    if (!session?.id) return;

    const eventNamesMap: { [key: string]: string } = {
      createdAt: 'Created at',
      startedAt: 'Started at',
      endedAt: 'Ended at',
    };

    const { createdAt, startedAt, endedAt } = session;
    const eventsInSession: { [key: string]: string } = {
      createdAt,
      startedAt,
      endedAt,
    };
    const newEventsLogs = Object.keys(eventsInSession).map(
      (eventKey: string) => ({
        sessionId: session.id,
        eventType: eventNamesMap[eventKey],
        createdAt: eventsInSession[eventKey],
      })
    );

    dispatch(sessionsActions.updateSelectedSessionEvents(newEventsLogs));
  };

  useEffect(() => {
    if (!open || !session?.id) return;
    handleGetSessionDetails(session);
    loadEvents();
  }, [open, session]);

  if (!session) return null;

  const handleTabChange = (domEvent: any, newValue: number) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      handleGetSessionDetails(session);
    } else {
      setSessionDetails(null);
    }
  };
  const handleReprocessSessionButtonPress = (isSilent: boolean) => {
    if (session?.id) {
      dispatch(
        postReprocess({
          uuid: session.id,
          isUser: false,
          isSilent: isSilent,
        })
      );
    }
  };

  const getSessionEntries = (session: Session) => {
    const { user = null } = session;
    const newSession: any = { ...session };
    if (user) {
      delete newSession.user;
      newSession.userEmail = user.email;
    }

    return Object.entries(newSession);
  };

  const fileItems = sessionDetails?.fileList.map(parseFileItemString);

  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth="md">
      <DialogContent style={{ minHeight: '700px' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Tabs
              TabIndicatorProps={{ style: { background: '#000000' } }}
              value={activeTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab label="Overview" key="overview" />
              <Tab label="Info" key="info" />
              <Tab label="Events Logs" key="events-logs" />
            </Tabs>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="x-clear"
              onClick={() => setOpen(false)}
              size="large"
            >
              <img src={xIcon} alt="x-icon" width="25" />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />

        {activeTab === 0 && sessionDetails != null && (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} key={session.id}>
                <Paper style={{ padding: 16 }}>
                  <Typography variant="h6">
                    Session ID : {session.id.toUpperCase()}
                  </Typography>
                </Paper>
                <br />
                <Divider />
                <Paper style={{ padding: 16 }}>
                  <Grid item xs={12} key="PostProcess">
                    <Typography variant="h6">Post Processing Results:</Typography>
                    <br />
                    <Typography>
                      {' '}
                      Is Report Valid :{' '}
                      {getValidityIcon(
                        sessionDetails.postProcessDiagnosis.reportValid
                      )}
                    </Typography>
                    <Typography>
                      {' '}
                      Does have Pulse oxy data :{' '}
                      {getValidityIcon(
                        sessionDetails.postProcessDiagnosis.doesHaveFinData
                      )}
                    </Typography>
                    <Typography>
                      {' '}
                      Session Duration :{' '}
                      {sessionDetails.postProcessDiagnosis.sessionDuration}
                    </Typography>
                  </Grid>
                </Paper>
                <br />
                <Divider />
              </Grid>
              {getPatchOrder(session).map(
                (device) =>
                  sessionDetails.debugData[device] && (
                    <Grid item xs={12} key={device}>
                      <Paper style={{ padding: 16 }}>
                        <Typography variant="h6">
                          Device: {renamePatch(device)}
                        </Typography>
                        <Typography>
                          Start Battery Level:{' '}
                          {formatToDecimalNumbers(
                            sessionDetails.debugData[device].startBatteryLevel
                          )}{' '}
                          mV
                        </Typography>
                        <Typography>
                          End Battery Level:{' '}
                          {formatToDecimalNumbers(
                            sessionDetails.debugData[device].endBatteryLevel
                          )}{' '}
                          mV
                        </Typography>
                        <Typography>
                          Last Index:{' '}
                          {formatLastIndex(
                            sessionDetails.debugData[device].lastIndex
                          )}
                        </Typography>
                        <Typography>
                          Number of Files:{' '}
                          {sessionDetails.debugData[device].numberOfFiles}
                        </Typography>
                        <Typography>
                          Number of Missing Files :{' '}
                          {getMissingFiles(
                            sessionDetails.debugData[device].lastIndex,
                            sessionDetails.debugData[device].numberOfFiles
                          )}
                        </Typography>
                        <Typography>
                          Total Hours of Data:{' '}
                          {sessionDetails.debugData[device].totalHoursOfData}
                        </Typography>
                      </Paper>
                    </Grid>
                  )
              )}
            </Grid>
            <br />
            <Divider />
            <Paper style={{ padding: 16 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} key="notes">
                  <Typography variant="h6">Notes:</Typography>
                </Grid>
                {sessionDetails.notes.length > 0 ? (
                  <div>
                    {sessionDetails.notes.map((note, index) => (
                      <Grid item xs={12} key={index}>
                        {note.issue && note.issue.length !== 0 ? (
                          <Typography key={index} style={{ marginLeft: 16 }}>
                            Issue : {note.issue}
                          </Typography>
                        ) : null}
                        {note.resolution && note.resolution.length !== 0 ? (
                          <Typography key={index} style={{ marginLeft: 16 }}>
                            Possible Resolution : {note.resolution}
                          </Typography>
                        ) : null}
                        <br />
                      </Grid>
                    ))}
                  </div>
                ) : (
                  <Grid item xs={12} key="none">
                    <Typography style={{ marginLeft: 16 }}>
                      {' '}
                      None available{' '}
                    </Typography>{' '}
                  </Grid>
                )}
              </Grid>
            </Paper>
            <br />
            <Divider />
            <Paper style={{ padding: 16 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} key="sessionList">
                  <Typography variant="h6">Raw File List:</Typography>
                  <br />
                </Grid>
                {fileItems && fileItems.length > 0 && (
                  <ExpandableList items={fileItems} />
                )}
              </Grid>
            </Paper>
            <br />
            <Divider />
            <br />
            <Paper style={{ padding: 16 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} key="TriggerInfo">
                  <Typography variant="h6">Session Trigger Info:</Typography>
                  <br />
                  <Typography>
                    {' '}
                    Trigger Source :{' '}
                    {sessionDetails.triggerInfo?.triggerSource || 'Unknown'}
                  </Typography>
                  <Typography>
                    {' '}
                    Triggered by :{' '}
                    {sessionDetails.triggerInfo?.triggerUserEmail || 'Unknown'}
                  </Typography>
                  <Typography>
                    {' '}
                    Did send user Notifications :{' '}
                    {sessionDetails.triggerInfo && sessionDetails.triggerInfo.wasSilent !== null && getValidityIcon(!sessionDetails.triggerInfo?.wasSilent) || 'Unknown'}
                  </Typography>
                  <Typography>
                    {' '}
                    Did Force Process :{' '}
                    {getValidityIcon(
                      sessionDetails.triggerInfo?.didForceReprocess
                    )}
                  </Typography>
                  <Typography>
                    {' '}
                    Did run auto fixer :{' '}
                    {getValidityIcon(
                      sessionDetails.triggerInfo?.didRunAutoFixer
                    )}
                  </Typography>
                </Grid>
                <br />
                <Divider />
              </Grid>
              <br />
            </Paper>
            <Divider />
            <Paper style={{ padding: 16 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} key="debug">
                  <Typography variant="h6">Debug dump:</Typography>
                  <br />
                </Grid>
                {sessionDetails.debugDump.length > 0 && (
                  <div>
                    <Typography
                      key="dump"
                      style={{ marginLeft: 16, whiteSpace: 'pre-line' }}
                    >
                      {sessionDetails.debugDump}
                    </Typography>
                  </div>
                )}
              </Grid>
            </Paper>
            <br />
            <Grid container spacing={2}>
              <Grid
                item
                xs={4}
                key="button3"
                color="error"
                onClick={() => handleReprocessSessionButtonPress(false)}
              >
                <Button variant="contained">Retrigger Session loudly</Button>
              </Grid>
              <Grid item xs={4} key="space2" />
              <Grid
                item
                xs={4}
                key="button1"
                color="error"
                onClick={() => handleReprocessSessionButtonPress(true)}
              >
                <Button variant="contained">Retrigger Session Silently</Button>
              </Grid>
            </Grid>
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <Grid container spacing={2}>
              {getSessionEntries(session).map((value: any) => (
                <Grid item xs={6} key={`${value[0]}-${value[1]}`}>
                  <SessionKey>{value[0]}</SessionKey>
                  <SessionValue>{value[1]}</SessionValue>
                </Grid>
              ))}
            </Grid>
            <Divider />
            <Grid container spacing={2}>
              <Grid
                item
                xs={4}
                key="button3"
                color="error"
                onClick={() => handleReprocessSessionButtonPress(false)}
              >
                <Button variant="contained">Retrigger Session loudly</Button>
              </Grid>
              <Grid item xs={4} key="space2" />
              <Grid
                item
                xs={4}
                key="button1"
                color="error"
                onClick={() => handleReprocessSessionButtonPress(true)}
              >
                <Button variant="contained">Retrigger Session Silently</Button>
              </Grid>
            </Grid>
          </div>
        )}

        {activeTab === 2 && (
          <div
            style={{
              opacity: eventsRequestStatus === loadStatus.loading ? '0.1' : '1',
            }}
          >
            {eventsRequestStatus === loadStatus.failed && eventsError ? (
              <ErrorCard path="/events" error={eventsError} />
            ) : (
              <>
                <EventsHeader>
                  <Caption>FLE* From Last Event</Caption>
                  <RefreshBtn
                    color="primary"
                    aria-label="refresh-icon"
                    onClick={loadEvents}
                  >
                    <img
                      src={refreshIcon}
                      alt="refresh-events-btn-icon"
                      width="18"
                    />
                  </RefreshBtn>
                </EventsHeader>
                <DividerMui style={{ marginTop: '10px' }} />
                {eventsRequestStatus === loadStatus.success && !eventsLogs.length ? (
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ marginTop: 50 }}
                  >
                    This Session has no events yet.
                  </Typography>
                ) : (
                  <Timeline>
                    {eventsLogs.map((eventLog: EventLog) => (
                      <TimelineItem
                        key={`${eventLog.eventType}-${randomString()}`}
                      >
                        <TimelineOppositeContent style={{ display: 'flex' }}>
                          <EventContent>
                            {formatMysqlDate(eventLog.createdAt, true)}
                          </EventContent>
                          {/* {index > 0 && (
                            <>
                              <TimeDivider orientation="vertical" />
                              <Caption>
                          + {datesDifference(eventLog.createdAt, eventsLogs[index - 1].created_at)} FLE
                              </Caption>
                            </>
                          )} */}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot
                            style={{
                              background: getStatusColor(eventLog.eventType),
                            }}
                          />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Paper elevation={3}>
                            <EventTitle>{eventLog.eventType}</EventTitle>
                          </Paper>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                )}
              </>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

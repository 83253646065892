import { configureStore } from '@reduxjs/toolkit';

import reducers from './reducers';

const middlewares: Array<any> = [];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { createLogger } = require('redux-logger');
  const logger = createLogger({ level: 'info', collapsed: true, logErrors: true });
  middlewares.push(logger);
}

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), ...middlewares],
});

export type RootState = ReturnType<typeof store.getState>;

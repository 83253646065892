import {
  decodeBitFlag,
  getServiceCharacteristic,
} from './bleHelper';


// tatch BLE services
export const tatchservices: any = {
  // 'battery' : {
  //     'uuid' : 0x180F
  // },
  dfu: {
    name: 'Secure DFU Service',
    uuid: 0xFE59,
    uuidFull: '0000fe59-0000-1000-8000-00805f9b34fb',
  },
  command: {
    uuid: 0xff20,
    uuidFull: '0000ff20-23e8-4679-94da-c1ca6a54c73a',
    characteristics: {
      command: '0000ff21-0000-1000-8000-00805f9b34fb',
    },
  },
  data: {
    uuid: 0xff30,
    uuidFull: '0000ff30-2c8c-43ff-b828-a9e7246255a6',
    characteristics: {
      data: '0000ff31-0000-1000-8000-00805f9b34fb',
    },
  },
  deviceInfo: {
    uuid: 0x180a,
    uuidFull: '0000180a-0000-1000-8000-00805f9b34fb',
    characteristics: {
      firmwareRevision: 0x2A26,
      hardwareRevision: 0x2a27,
      modelNumber: 0x2a24,
      systemId: 0x2a23,
      manufacturerName: 0x2a29,
    },
  },
  spo2: {
    uuid: 0x180d,
    uuidFull: '0000180d-0000-1000-8000-00805f9b34fb',
    characteristics: {
      sensorLocation: '00002a38-0000-1000-8000-00805f9b34fb',
      heartRate: '00002a37-0000-1000-8000-00805f9b34fb',
      spo2: '0000ff40-0000-1000-8000-00805f9b34fb',
    },
  },
};


export interface ITatchSpO2Data {
  spo2: number;               /* spo2 in percent */
  spo2_confidence: number;    /* percent */
  hr: number;
  hr_confidence: number;      /* percent */
  r: number;                  /* 0 to 255 */
}

/**
 * Decoded data read from the heart rate characteristic
 */
interface HRCharacteristicData {
  // flags
  uses_uint16: boolean;
  supports_sensor_contact: boolean;
  sensor_is_in_contact: boolean;
  supports_energy_expended: boolean;
  supports_rr: boolean;

  // uint8 or uint16
  hr_value: number;
  // uint16, if present
  energy_expended: number | undefined;
  // either 8 or 9 RR intervals, depending on if this uses uint16 or uint8 for hr_value
  rr_values: number[] | undefined;
}

/**
 * Decoded data read from the spo2 characteristic
 */
interface SpO2CharacteristicData {
  spo2: number;               /* spo2 in percent */
  spo2_confidence: number;    /* percent */
  hr_confidence: number;      /* percent */
  r: number;                  /* 0 to 255 */
}


/**
 * Represents the tatch live data service.
 */
export class TatchSpO2Service {
  _onUpdate: ((data: ITatchSpO2Data) => void) | undefined;
  _lastReading: ITatchSpO2Data;

  // characteristics for this service
  _hr_c: BluetoothRemoteGATTCharacteristic;
  _spo2_c: BluetoothRemoteGATTCharacteristic;
  _loc_c: BluetoothRemoteGATTCharacteristic;

  constructor(hr_c: BluetoothRemoteGATTCharacteristic, spo2_c: BluetoothRemoteGATTCharacteristic, loc_c: BluetoothRemoteGATTCharacteristic) {
    this._lastReading = {
      spo2: 0,
      spo2_confidence: 0,
      hr: 0,
      hr_confidence: 0,
      r: 0,
    };
    this._hr_c = hr_c;
    this._spo2_c = spo2_c;
    this._loc_c = loc_c;
    this._onHeartRateNotify = this._onHeartRateNotify.bind(this);

  }

  async startNotify(onUpdate: (data: ITatchSpO2Data) => void): Promise<void> {
    this._onUpdate = onUpdate;

    // set up the notify callback
    this._hr_c.addEventListener('characteristicvaluechanged', this._onHeartRateNotify);
    this._hr_c.startNotifications();
  }

  async stopNotify(): Promise<void> {
    this._hr_c.stopNotifications();
  }

  async _onHeartRateNotify(event: any): Promise<void> {
    const hr_data = TatchSpO2Service.decodeHeartRateCharacteristicValue(event.target.value);
    // read the spo2 data
    const value = await this._spo2_c.readValue();
    const spo2_data = TatchSpO2Service.decodeSpO2CharacteristicValue(value);
    this._lastReading = {
      hr: hr_data.hr_value,
      hr_confidence: spo2_data.hr_confidence,
      spo2: spo2_data.spo2,
      spo2_confidence: spo2_data.spo2_confidence,
      r: spo2_data.r,
    };
    this._onUpdate?.(this._lastReading);
  }

  /**
   *
   * @returns sensor location as an integer, as specified in SensorLocation in bleEnums.ts
   */
  async getSensorLocation(): Promise<number> {
    console.log('Getting sensor location...');

    return (await this._loc_c.readValue()).getInt8(0);
  }

  static decodeHeartRateCharacteristicValue(value: DataView): HRCharacteristicData {
    const flags = value.getUint8(0);
    const uses_uint16 = decodeBitFlag(flags, 0);
    const data: HRCharacteristicData = {
      uses_uint16: uses_uint16,
      supports_sensor_contact: decodeBitFlag(flags, 1),
      sensor_is_in_contact: decodeBitFlag(flags, 2),
      supports_energy_expended: decodeBitFlag(flags, 3),
      supports_rr: decodeBitFlag(flags, 4),

      hr_value: 0,  // temporary filler
      energy_expended: undefined,
      rr_values: undefined,
    };

    // populate the rest of the data based on the flags
    let idx = 1;
    if (data.uses_uint16) {
      data.hr_value = value.getUint16(idx);
      idx += 2;
    }
    else {
      data.hr_value = value.getUint8(idx);
      idx += 1;
    }

    if (data.supports_energy_expended) {
      data.energy_expended = value.getUint16(idx);
      idx += 2;
    }

    if (idx < value.byteLength) {
      data.rr_values = new Array<number>();
    }
    while (idx < value.byteLength) {
      data.rr_values?.push(value.getUint16(idx));
      idx += 2;
    }

    return data;
  }

  static decodeSpO2CharacteristicValue(value: DataView): SpO2CharacteristicData {
    return {
      spo2: value.getUint8(0),
      spo2_confidence: value.getUint8(1),
      hr_confidence: value.getUint8(2),
      r: value.getUint8(3),
    };
  }

  static async connect(device: BluetoothDevice, server: Promise<BluetoothRemoteGATTServer>): Promise<TatchSpO2Service | undefined> {
    try {
      const hr_c = await getServiceCharacteristic(
        server,
        tatchservices.spo2.uuidFull,
        tatchservices.spo2.characteristics.heartRate,
      );
      const spo2_c = await getServiceCharacteristic(
        server,
        tatchservices.spo2.uuidFull,
        tatchservices.spo2.characteristics.spo2,
      );
      const loc_c = await getServiceCharacteristic(
        server,
        tatchservices.spo2.uuidFull,
        tatchservices.spo2.characteristics.sensorLocation,
      );

      return new TatchSpO2Service(hr_c, spo2_c, loc_c);
    }
    catch (err) {
      console.log('Did not connect to live HR/SpO2 service.');
      console.log(err);

      return undefined;
    }
  }
}

export const CommandMessagedId = Object.freeze({
  ACK: 0x01,
  ERROR: 0x02,
  DISCONNECT_REQUEST: 0x08,
  GET_DEVICE_STATE: 0x10,
  FORMAT_STORAGE: 0x11,
  RECORD_TIME: 0x12,
  SET_PATCH_ID: 0x13,
  RESET_DEVICE: 0x14,
  SHUTDOWN_DEVICE: 0x15,
  GET_ERROR: 0x16,
  START_DATA_ACQUISITION: 0x20,
  STOP_DATA_ACQUISITION: 0x21,
  ACK_PAGE_RECEIVED: 0x22,
  SET_ANALOG_CONFIG: 0x80,
  SET_POX_CONFIG: 0x81,
  SET_MISC_CONFIG: 0x82,
});

export const DataMessageId = Object.freeze({
  PAGE_DATA_START: 0x10,
  PAGE_DATA_END: 0x11,
  PAGE_ERROR: 0x12,
  ALL_PAGES_SENT: 0x13,
  METADATA_SESSION_START: 0x2A,
  METADATA_SESSION_END: 0x2B,
  METADATA_SENSOR_GROUP: 0x2C,
  ANALOG_SENSOR_GROUP: 0x31,
  POX_SENSOR_GROUP: 0x32,
  MISC_SENSOR_GROUP: 0x33,
  ACTIVITY_RECORD: 0x34,
  ANALOG_RESP_SENSOR_CONFIG: 0x41,
  ANALOG_ACCEL_SENSOR_CONFIG: 0x42,
  POX_SENSOR_CONFIG: 0x43,
  MISC_SENSOR_CONFIG: 0x44,
});

export const MAX_DATA_PACKET_LEN = 23;

export const DeviceState: any = Object.freeze({
  NONE: 0x00,
  INIT: 0x01,
  LEDCHECK_R: 0x02,
  LEDCHECK_G: 0x03,
  LEDCHECK_B: 0x04,
  IDLE: 0x05,
  CONNECTED: 0x06,
  ERROR: 0x07,
  RECORDING: 0x08,
  STOP_RECORDING: 0x09,
  SHUTDOWN_PREPARE: 0x0A,
  SHUTDOWN_CANCEL: 0x0B,
  CATCHING_UP: 0x0C,
  UNKNOWN: 0x0D,
});

export const PatchStatus = Object.freeze({
  CONNECTED: 1,
  CONNECTING: 2,
  ERROR: 3,
  DISCONNECTED: 4,
});

export const SensorLocation = Object.freeze({
  OTHER: 0,
  CHEST: 1,
  WRIST: 2,
  FINGER: 3,
});

export const ActivityRecordType = Object.freeze({
  ERROR: 0x01,
  BLE_CONNECTED: 0x02,
  BLE_DISCONNECTED: 0x03,
  BLE_TIMEOUT_DISCONNECT: 0x04,
  SESSION_START: 0x05,
  SESSION_END: 0x06,
  LOW_POWER: 0x07,
  RECORD_TIME: 0x08,
  CONNECTION_PARAMETER_UPDATED: 0x09,
});

export const FileSyncStatus = Object.freeze({
  IDLE: 'idle',
  IN_PROGRESS: 'inProgress',
  FINISHED: 'finished',
});

export const CHANNEL = Object.freeze({
  MOVEMENT: 'MOVEMENT',
  EFFORT: 'EFFORT',
  PPG: 'PPG',
});

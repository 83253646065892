import { User } from 'src/store/modules/userSlice';

/**
 * Read the information from jwt and return a json with the token info
 */
export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

/**
 * Check if the given token has a exp valid value
 */
export const isTokenValid = (token: string) => {
  const { exp } = parseJwt(token);
  const expire_date = new Date(exp * 1000);

  return new Date() < expire_date;
};

/**
 * Get the necessary user info
 */
export const getUserInfoFromToken = (token: string): User => {
  const { email, name, groups = '' } = parseJwt(token);
  const userInfo = { email, name, groups: groups.split(',') };

  return userInfo;
};

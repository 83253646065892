import { axios } from './axiosMiddleware';
import { jwtStorageName } from './constants';

type Config = {
  headers?: {
    [key: string]: any;
  };
  params?: {
    [key: string]: any;
  };
}

type GetProps = {
  link: string;
  config?: Config;
}

type PostProps = {
  link: string;
  config?: Config;
  params?: Record<string, unknown>;
}

const setDefaultHeaders = async (config: Config = {}) => {
  const token = localStorage.getItem(jwtStorageName);
  const defaultHeaders = { Authorization: `Bearer ${token}` };
  const headers = { ...defaultHeaders, ...config?.headers };

  return headers;
};

export const axiosGet = async ({ link, config = {} }: GetProps) => {
  const headers = await setDefaultHeaders(config);
  const finalConfig = { ...config, headers };
  const response = await axios.get(link, finalConfig);

  return response.data;
};

export const axiosPost = async ({ link, params = {}, config = {} }: PostProps) => {
  const headers = await setDefaultHeaders(config);
  const finalConfig = { ...config, headers };
  const response = await axios.post(link, params, finalConfig);

  return response.data;
};

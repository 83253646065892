import {
  differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds,
  format, isValid, parse, 
} from 'date-fns';

export const dateParseMysql = (dateString: string) => {
  const date = parse(dateString, 'y-MM-dd HH:mm:ss', new Date());
  if (!isValid(date)) return null;

  return date;
};

export const formatMysqlDate = (dateString: string, withSeconds = false) => {
  const date = new Date(dateString);
  if (!isValid(date)) return null;
  if (!date) return '-';

  if (withSeconds) {
    const finalDate = format(date, 'MM/dd/y hh:mm:ss aa');

    return finalDate;
  }
  const finalDate = format(date, 'MM/dd/y hh:mm aa');

  return finalDate;
};

/**
 * Get the difference between two dates strings
 * ex: date1 - date2 = 2hrs, 1min, 43s
 */
export const datesDifference = (dateString1: string, dateString2: string): string => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  if (!isValid(date1) || !isValid(date2)) return '-';

  const daysValue = differenceInDays(date1, date2);
  let days = daysValue === 0 ? '' : `${daysValue} day,`;
  if (daysValue > 1) days = `${daysValue} days,`;

  const hrsValue = differenceInHours(date1, date2);
  const finalHrs = hrsValue - (24 * daysValue);
  let hours = finalHrs === 0 ? '' : `${finalHrs} h,`;
  if (finalHrs > 1) hours = `${finalHrs} hrs,`;

  const minsValue = differenceInMinutes(date1, date2);
  const finalMins = minsValue - (60 * hrsValue);
  let minutes = finalMins === 0 ? '' : `${finalMins} min,`;
  if (finalMins > 1) minutes = `${finalMins} mins,`;

  const secsValue = differenceInSeconds(date1, date2);
  const finalSecs = secsValue - (60 * minsValue);
  let seconds = finalSecs === 0 ? '' : `${finalSecs} s`;
  if (finalSecs > 1) seconds = `${finalSecs} s`;

  let difference = `${days} ${hours} ${minutes} ${seconds}`;
  const lastCharIndex = difference.length - 1;
  if (difference[lastCharIndex] === ',') difference = difference.slice(0, -1);
  if (difference[lastCharIndex - 1] === ',') difference = difference.slice(0, -2);

  return difference;
};

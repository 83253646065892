
export const formatToDecimalNumbers = (str: null | string) => {
  const number = Number(str);
  if (number) {
    return number.toFixed(2);
  } else {
    return 'N/A';
  }

};

export const routes = {
  home: '/',
  wesperMon: '/wesper-mon',
  kitGroupForm: '/kit-group-form',
};

export const dateColumns = ['start', 'end', 'updatedAt', 'processingDateTime'];

export const loadStatus = {
  neverLoaded: 'neverLoaded',
  loading: 'loading',
  success: 'success',
  failed: 'failed',
};

export const jwtStorageName = 'WESPER_TOKEN';

export const userRoles = {
  admin: 'role_admin',
  hardware: 'role_hardware_diagnostics',
  manufacturer: 'role_manufacturer',
};

export const API_PATHS = {
  SESSIONS: '/sessions',
  KIT_GROUPS: '/kit-groups',
  SESSION_REPROCESS: '/sessions/reprocess',
  FIX_SESSION: '/sessions/fix',
  SESSION_DETAILS: '/support/session-details',
};

export const patchesIdTypes = {
  serialNumber: 'SERIAL_NUMBER',
  factoryId: 'FACTORY_ID',
};

export const patchesIdNames = {
  serialNumber: 'Serial Number',
  factoryId: 'Factory ID',
};

export enum sessionType {
  DEFAULT = 'two_component',
  WELLNESS = 'two_component',
  DIAGNOSTIC = 'three_component',
  ONE_COMPONENT = 'one_component',
  TWO_COMPONENT = 'two_component',
  THREE_COMPONENT = 'three_component',
}

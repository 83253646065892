import React from 'react';
import { Route, Routes as RoutesDom } from 'react-router-dom';

import { useTypedSelector } from 'src/hooks';
import {
  KitGroupForm,
  Sessions, WesperMon,
} from 'src/pages';
import { isUserAllowed } from 'src/utils/auth';
import { routes, userRoles } from 'src/utils/constants';

export const Routes = () => {
  const { currentUser } = useTypedSelector(({ user }) => user);


  if (currentUser?.groups && currentUser.groups.includes(userRoles.hardware)) {
    return (
      <RoutesDom>
        <Route path={routes.home} element={<WesperMon />}></Route>
        <Route path={routes.wesperMon} element={<WesperMon />}></Route>
        {isUserAllowed(userRoles.manufacturer) && <Route path={routes.kitGroupForm} element={<KitGroupForm />}></Route>}
      </RoutesDom>
    );
  }

  if (
    currentUser?.groups
    && currentUser.groups.includes(userRoles.manufacturer)
    && !currentUser.groups.includes(userRoles.hardware)
  ) {
    return (
      <RoutesDom>
        <Route path={routes.home} element={<KitGroupForm />}></Route>
        <Route path={routes.kitGroupForm} element={<KitGroupForm />}></Route>
      </RoutesDom>
    );
  }

  return (
    <RoutesDom>
      <Route path={routes.home} element={<Sessions />}></Route>
      <Route path={routes.wesperMon} element={<WesperMon />}></Route>
      <Route path={routes.kitGroupForm} element={<KitGroupForm />}></Route>
    </RoutesDom>
  );
};

export const colors = {
  lightGray: '#f2f2f2',
  darkGray: '#424242',
  lightGrayHover: '#fafafa',
  green: '#4caf50',
  blue: '#4287f5',
  darkBlue: '#3167bd',
  messages: {
    success: '#4caf50',
    warning: '#ff9800',
    error: '#fa6357',
  },
  errorLabel: '#e85656',
};

import React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

import { colors } from 'src/utils/colors';

const ToastStyled = styled(ToastContainer)`
  z-index: 10000;
  .Toastify__toast--success {
    background-color: ${colors.messages.success};
  }
  .Toastify__toast--warning {
    background-color: ${colors.messages.warning};
  }
  .Toastify__toast--error {
    background-color: ${colors.messages.error};
  }
`;

export const Toast = () => <ToastStyled position="bottom-center" autoClose={5000} />;

import React from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

type Props = {
  path: string;
  error: any;
};

const Container = styled.div`
  border: 1px solid #FF7979;
  border-radius: 3px;
  padding: 20px;
  width: 90%;
  margin: 0 auto;
`;

export const ErrorCard = ({ path, error }: Props) => {
  return (
    <Container>
      <Typography variant="h5">
        Request to {path} path failed.
      </Typography>
      <Typography variant="body1">
        {error}
      </Typography>
    </Container>
  );
};

/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* Module containing code to manage patches over BLE. */

import { saveAs } from 'file-saver';

import {
  ITatchSpO2Data,
  tatchservices,
  TatchSpO2Service,
} from './bleCustomServices';
import {
  CommandMessagedId,
  DataMessageId,
  DeviceState,
  MAX_DATA_PACKET_LEN,
} from './bleEnums';
import {
  buf2hex,
  DeviceInfo,
  exponentialBackoff,
  getDeviceInfo,
  logHex,
  nowMsUint8,
  time,
} from './bleHelper';

/* GLOBAL VARS */

// list of firmware versions which use the new data service protocol, which may send
// multiple packets in a transmission
const dataProtocolTwoSupportedFirmwareVersions: (string | undefined)[] = [
  '2.1.1',
  '2.4.1',
];

const uuids: Array<string> = [];
for (const name in tatchservices) {
  uuids.push(tatchservices[name].uuidFull);
}

/* FUNCTIONS & CLASSES */

/**
* Connects to patch, if avail
*
* @return     {PatchManager}  { Manager for the patch the user selected.}
*/
export async function connectToPatch (
  onDisconnect?: ((wasCommandedToDisconnect: boolean) => void) | undefined
): Promise<PatchManager> {
  // connect to a patch--this will open a browser dialog for the user to
  // select one. filtering by name because the services are not necessarily
  // advertised in full by the device firmware.
  const device = await navigator.bluetooth.requestDevice({
    filters: [{ namePrefix: 'TPCH' }],
    optionalServices: uuids,
  });

  return new PatchManager(device, onDisconnect);
}

interface PatchStateInfo {
  success: boolean;
  rawMsg: string;
  state: number | undefined;
  remainingPages: number | undefined;
  batteryVoltage: number | undefined;
  stateString: string | undefined;
}

class Session {
  startTime: number;
  packets: Array<Array<number>> = [];
  catchupTimeoutTimer: NodeJS.Timeout | undefined;
  patchState: PatchStateInfo;
  pagesReceived = 0;

  constructor(state: PatchStateInfo) {
    this.startTime = Date.now();
    this.patchState = state;
  }
}

export class RecoverySession extends Session {
  pagesToRecover: number;
  recoveryUpdateCallback: undefined | ((session: RecoverySession) => void) = undefined;
  error: undefined | string;

  constructor(state: PatchStateInfo) {
    super(state);
    this.pagesToRecover = state.remainingPages ? state.remainingPages : 0;
  }
}

/**
* Manager for all intereactions with patches. Has one helluva problem with race conditions
* since it's just a quick prototype. Handle with care. Don't call functions too close together.
*
* @class      PatchManager (name)
*/
export class PatchManager {
  _device: BluetoothDevice;
  _server: Promise<BluetoothRemoteGATTServer>;
  name: string;
  id: string;
  session: Session | undefined;
  catchupTimeout: any;
  deviceInfo: DeviceInfo | undefined;
  disconnectCallback: ((byCommand: boolean) => void) | undefined;
  wasCommandedToDisconnect: boolean;
  _data_characteristic: BluetoothRemoteGATTCharacteristic | undefined;
  _command_characteristic: BluetoothRemoteGATTCharacteristic | undefined;
  _spo2_service: TatchSpO2Service | undefined;

  constructor(device: BluetoothDevice, disconnectCallback: ((byCommand: boolean) => void) | undefined = undefined) {
    this._device = device;
    if (!device.gatt) throw Error('Bluetooth device has no gatt server.');
    // this is a promise--make sure you await before use.
    this._server = device.gatt.connect()
      .then((server: BluetoothRemoteGATTServer) => {
        console.log(`Connected to patch ${device.name}.`);

        return server;
      });
    this.name = this._device.name ?? '';
    this.id = this._device.id;
    this.disconnectCallback = disconnectCallback;
    this.wasCommandedToDisconnect = false;

    this.handleDataArrived = this.handleDataArrived.bind(this);
    device.addEventListener('gattserverdisconnected', this.onDisconnect.bind(this));

    this.catchupTimeout = 15 * 1000; // 15 secs
    this.deviceInfo = undefined;

    this.initializeServices(this._device, this._server);

    return this;
  }

  async initializeServices(device: BluetoothDevice, server: Promise<BluetoothRemoteGATTServer>): Promise<TatchSpO2Service | undefined | void> {
    // set data and command services to null to ensure that they get (re)initialized before use
    this._data_characteristic = undefined;
    this._command_characteristic = undefined;

    // set up the spo2 service
    return TatchSpO2Service.connect(this._device, this._server).then( spo2_service => {
      this._spo2_service = spo2_service;
      this._spo2_service?.getSensorLocation();
    });
  }

  /**
   * Get all the info from the deviceInfo service
   *
   * @return     {Promise}  A DeviceInfo instance
   */
  async getDeviceInfo(): Promise<DeviceInfo> {
    if (!this.deviceInfo) {
    // only need to do this once. The deviceInfo won't change.
      const server = await this._server;
      this.deviceInfo = await getDeviceInfo(server);
    }

    return this.deviceInfo;
  }

  /**
   * Disconnects from the patch
   */
  disconnect() {
    const disconnectCommand = new Uint8Array([0x80, 0x00, 0x08]);
    this._sendCommand(disconnectCommand, false);
    this.wasCommandedToDisconnect = true;
  }

  /**
   * Called upon on patch disconnect as reported by a BLE event
   *
   * @param      {<type>}  event   The event
   */
  onDisconnect(event: any) {
    console.log(`Disconnected from patch ${event.target.name}.`);
    this._command_characteristic = undefined;
    this._data_characteristic = undefined;
    if (this.disconnectCallback)
      this.disconnectCallback(this.wasCommandedToDisconnect);

    if (!this.wasCommandedToDisconnect) {
      console.log('Attempting to reconnect...');
      this.reconnect();
    }
  }

  /**
   * attempt to reconnect to this patch (called when we disconnect)
   *
   */
  async reconnect() {
    console.log('searching...');

    return await exponentialBackoff(4 /* max retries */, 2 /* seconds delay */,
    	/* toTry */ async () => {
        time('Connecting to Bluetooth Device... ');

        // note: on macOS this call doesn't ever return unless a connection is made.
        // It just endlessly loops trying to connect. I got this example code from
        // the official web ble docs (see my notes on the exponentialBackoff function),
        // so I'm assuming perhaps this works on windows and
        // it's worth leaving this full function in.
        let server = await this._device.gatt?.connect();

        return server;
      },
      /* success */ (server: BluetoothRemoteGATTServer | undefined) => {
        console.log(`Reconnected to patch ${this.name}.`);

        return this.initializeServices(this._device, this._server);
      },
      /* fail */ () => {
        time('Failed to reconnect.');
      });
  }

  /**
   * Powers off the patch. Implicitly disconnects as well.
   *
   * @return     {Promise}  { description_of_the_return_value }
   */
  async powerOff() {
    const powerOffCommand = new Uint8Array([0x80, 0x00, 0x15]);
    try {
      await this._sendCommand(powerOffCommand, false);
    } catch(err) {
      // we're expecting a DOMException here due to the patch turning off
      // mid-BLE transaction
      if (!(err instanceof DOMException)) {
        throw err;
      }
    }
    console.log(`Powered off patch ${this.name}`);
  }

  async reset() {
    const resetCommand = new Uint8Array([0x80, 0x00, 0x14]);
    try {
      // sometimes, reset will send a response.
      let resp = await this._sendCommand(resetCommand, true);
      logHex(resp, 'Reset response: ');
    } catch(err) {
      // we're expecting a DOMException here due to the patch turning off
      // mid-BLE transaction
      if (!(err instanceof DOMException)) {
        throw err;
      }
    }
    console.log(`Reset patch ${this.name}`);
  }

  async getDeviceState(): Promise<PatchStateInfo> {
    const getStateCommand = new Uint8Array([0x80, 0x00, 0x10]);
    let resp: any = await this._sendCommand(getStateCommand, true);

    let stateInfo: any = {
      rawMsg: buf2hex(resp.buffer),
    };
    if (resp.getUint8(3, true) !== CommandMessagedId.ERROR) {
      stateInfo = {
        ...stateInfo,
        success: true,
        state: resp.getUint8(3),
        remainingPages: resp.getUint16(4, true /*little endian*/),
        batteryVoltage: resp.getUint16(6, true /*le*/),
      };
      stateInfo['stateString'] = Object.keys(DeviceState).find(key => {
        return DeviceState[key] === stateInfo.state;
      });
      if (this.session?.patchState) {
        // update the state info for the session if there is one.
        this.session.patchState = stateInfo;
      }
    }
    else {
      stateInfo['success'] = false;
    }

    return stateInfo;
  }

  async getError() {
    const getErrorCommand = new Uint8Array([0x80, 0x00, CommandMessagedId.GET_ERROR]);
    const resp: any = await this._sendCommand(getErrorCommand, true);
    let errorInfo: any = { isError: false };

    if (resp.getUint8(3, true) !== CommandMessagedId.ERROR) {
      errorInfo = {
        isError: true,
        code: resp.getUint16(3, true /*Little endian*/).toString(16),
        lineNo: resp.getUint16(5, true /*little endian*/),
        fileName: new TextDecoder('ascii').decode(new Uint8Array(resp.buffer, 7)),
        rawMsg: buf2hex(resp.buffer),
      };
    }

    return errorInfo;
  }

  async formatStorage() {
    const fmtStorageCommand = new Uint8Array([0x80, 0x00, CommandMessagedId.FORMAT_STORAGE]);
    const resp: any = await this._sendCommand(fmtStorageCommand, true);

    return buf2hex(resp.buffer);
  }

  /**
   * Starts a session.
   *
   * @return     {Promise}  { description_of_the_return_value }
   */
  async startSession(onDataUpdate: (data: ITatchSpO2Data) => void) {
    // clear out the session struct
    this.session = new Session(await this.getDeviceState());

    let cmdToSend = undefined;

    switch(this.session.patchState.state) {
    case DeviceState.CONNECTED:
      // continue to start a normal session

      // set up the command to start the session
      const prefix: any = new Uint8Array([0x80, 0x04, 0x20]);
      cmdToSend = new Uint8Array([...prefix, ...nowMsUint8()]);

      break;
    case DeviceState.RECORDING:
      // just start listening to data. a session's already begun.
      // send a new page req to start things off
      console.log('a session is already in progress...continue collecting data');
      cmdToSend = new Uint8Array([0x80, 0x00, CommandMessagedId.ACK_PAGE_RECEIVED]);
      break;
    default:
      let errmsg = "can't start session from state" + this.session.patchState.stateString;
      console.error(errmsg);
      throw new Error(errmsg);
    }

    // start listening for notifications from the data service
    const data = await this._getDataCharacteristic();
    await data.startNotifications();
    console.log('Now receiving notifications from data service...');

    // and from the live spo2 service, if relevant
    this._spo2_service?.startNotify(onDataUpdate);

    // if specified above, tell the patch to start the session.
    if (cmdToSend) {
      console.log('Sending command...');
      let resp = await this._sendCommand(cmdToSend, true);

      return resp;
    }
  }

  /**
   * Starts recovery of data for session stuck in catchup
   *
   * @param onRecoveryComplete a callback to be called when recovery is done
   *
   */
  async startSessionRecovery(onRecoveryUpdate: undefined | ((session: RecoverySession) => void) = undefined) {
    // check the state of the device
    let stateData = await this.getDeviceState();
    let session = new RecoverySession(stateData);
    session.recoveryUpdateCallback = onRecoveryUpdate;

    function onError(msg: string) {
      session.error = msg;
      console.error(msg);
      session.recoveryUpdateCallback?.(session);
    }

    if (!stateData.success) {
      onError('Failed to fetch device state');

      return;
    }

    switch (stateData.state) {
    case DeviceState.CATCHING_UP:
      console.log('Catchup state detected. Recovering...');
      // start a "normal" session--the timestamps will have to be rectified later
      this.session = session;

      // start listening for notifications from the data service
      const data = await this._getDataCharacteristic();
      await data.startNotifications();
      console.log('Now receiving notifications from data service...');

      // send a page ack to start getting new data back
      const pageEndAck = new Uint8Array([0x80, 0x00, CommandMessagedId.ACK_PAGE_RECEIVED]);
      this._sendCommand(pageEndAck, true);

      return;
    default:
      onError('Cannot recover from state ' + stateData.stateString);

      return;
    }
  }

  /**
   * Converts a single BLE transmission unit's bytes into actual data service packets
   *
   * @param transmission transmission from the data service
   * @returns a list of packets contained within the transmission
   */
  transmissionToPackets(transmission: Uint8Array): Array<Uint8Array> {
    let packets: Uint8Array[] = [];
    if (dataProtocolTwoSupportedFirmwareVersions.includes(this.deviceInfo?.firmwareRevision)) {
      let idx = 0;
      while (idx < transmission.length) {
        // the MSB of the len byte is the valid bit--the remaining 7 bits are the len
        let len_byte = transmission[idx] & 0x7F;
        // console.log("len byte: %d", len_byte);
        if (idx + 1 + len_byte > transmission.length) {
          // this is an error--we're about to overflow the array
          break;
        }
        let slice = transmission.buffer.slice(idx + 1, idx + 1 + len_byte);
        packets.push(new Uint8Array(slice));
        idx += 1 + len_byte;
      }
      if (idx != transmission.length) {
        console.error('Transmission does not fit valid protocol: (idx %d != len %d', idx, transmission.length);
        // console.error(transmission);
        logHex(transmission, 'Transmission');
      }
      console.log('%d packets found in transmission', packets.length);
      if (packets.length == 1 && packets[0][0] == 0) {
        console.log('faulty transmission: ');
        console.log(transmission);
      }
    }
    else {
      if (transmission.length > MAX_DATA_PACKET_LEN) {
        let errMsg = `Recieved a packet of length ${transmission.length} > max packet length. \
       If this firmware version is using data protocol 2.0, make sure you add it to the \
       batchPacketProtocolVersions list in patchManager.ts`;
        console.error(errMsg);
        alert(errMsg);
      }
      packets = [transmission];
    }

    return packets;
  }

  /**
   * Handle notifications from the data service. This only happens during a session
   * or during catch up. TODO eventually this should be part of some session object
   *
   * @param      {<type>}  event   The event
   */
  handleDataArrived(event: any) {
    if (!this.session) {
      return;
    }
    const packets = this.transmissionToPackets(new Uint8Array(event.target.value.buffer));
    console.log(packets);

    for (let idx = 0; idx < packets.length; idx++) {
      const packet = packets[idx];
      const line = this.packet2OutputLineArray(packet);
      this.session.packets.push(line);
      // restart the timeout timer, if necessary
      if (this.session.catchupTimeoutTimer) {
        clearInterval(this.session.catchupTimeoutTimer);
        this.session.catchupTimeoutTimer = setTimeout(_ => {
          console.log('catchup timeout occurred.');
          this._endSession();
        }, 10 * 1000);
      }
      let pageEndAck = new Uint8Array([0x80, 0x00, CommandMessagedId.ACK_PAGE_RECEIVED]);
      // data flow packets
      switch(packet[0]) {
      case DataMessageId.PAGE_DATA_START:
        console.log('Page start received.');
        break;

        // for now, just keep metadata with everything else
      case DataMessageId.METADATA_SESSION_END:
      case DataMessageId.PAGE_DATA_END:
        console.log('Page end received. Requesting another...');
        this.session.pagesReceived++;
        this._sendCommand(pageEndAck, true);
        if (this.session instanceof RecoverySession) {
          this.session.recoveryUpdateCallback?.(this.session);
        }
        break;

      case DataMessageId.PAGE_ERROR:
        logHex(event.target.value, 'Page Error Received: ');
        this._sendCommand(pageEndAck, true);
        break;

      case DataMessageId.ALL_PAGES_SENT:
        console.log('All pages received. Closing out session.');
        this._sendCommand(pageEndAck, true);
        this._endSession();
        break;

      default:
        break;
      }

    }
  }

  /**
   * Convert raw protocol data in a Uint8Array to an array of "csv" fields for the
   * raw protocol output file
   *
   * @param      {<type>}  packet  The packet
   */
  packet2OutputLineArray(packet: Uint8Array): Array<number> {
    //prepend the current session timestamp to the line
    if (!this.session) {
      throw 'No session available to determine start time';
    }
    let packetArray = Array.from(packet);
    packetArray.unshift(Date.now() - this.session.startTime);

    return packetArray;
  }

  /**
   * Stops a session.
   *
   * @return     {Promise}  { description_of_the_return_value }
   */
  async stopSession() {
    if (!this.session) {
      return;
    }
    const stopSessionCmd = new Uint8Array([0x80, 0x00, 0x21]);
    console.log('Stopping session...');
    let resp = await this._sendCommand(stopSessionCmd, true);
    // receive the remaining pages in catch-up mode.
    // for now, if we don't finish the catch up in 10 seconds, give up
    // and finish out the session. This is to help with debugging.
    this.session.catchupTimeoutTimer = setTimeout(_ => {
      console.log('catchup timeout occurred.');
      if (!this.session) {
        return;
      }

      if (this.session.packets.length > 0) {
        this._endSession();
      }
      else {
        console.log('Session end timeout elapsed, but we have no packets. skipping write');
      }
    }, 10 * 1000);
    this._spo2_service?.stopNotify();

    return resp;
  }

  async _endSession() {
    // cancel the stop session timeout
    if (this.session?.catchupTimeoutTimer) {
      clearInterval(this.session.catchupTimeoutTimer);
    }
    // stop receiving data
    const data = await this._getDataCharacteristic();
    await data.stopNotifications();
    console.log('Disabling notifications from data service...');
    data.removeEventListener('characteristicvaluechanged', this.handleDataArrived);
    this.saveSessionToFile();
    this.session = undefined;
  }

  /**
   * For testing. Will send a command (see source a bunch of times)
   *
   * @return     {Promise}  None
   */
  async spamCommand() {
    const getStateCommand = new Uint8Array([0x80, 0x00, 0x10]);
    let n_cmds = 3;
    console.log('Sending ' + n_cmds + ' commands');
    for (let i = 0; i < 10; i++) {
      const recordTimeCommand = new Uint8Array([0x80, 0x00, 0x12, ...nowMsUint8()]);
      await this._sendCommand(recordTimeCommand, true);
      await this._sendCommand(getStateCommand, true);
    }
  }

  /**
   * Sends a command to the patch
   *
   * @param      {<type>}   cmd                    The command
   * @param      {boolean}  [expectResponse=true]  Whether to expect a response to the msg
   * @return     {Promise}  Promise containing the command response from the patch.
   */
  async _sendCommand(cmd: Uint8Array, expectResponse = true): Promise<DataView | undefined> {
    const cmdChar = await this._getCommandCharacteristic();
    // the patches don't actually encode responses in a BLE response packet.
    // instead, they set the characteristic value to something new and send a notify
    // message. we have to wait for that.
    logHex(cmd, 'CMD: ');
    if (expectResponse) {
      cmdChar.startNotifications();
      const notificationPromise = new Promise<DataView>((resolve, reject) => {
        cmdChar.addEventListener(
          'characteristicvaluechanged',
          async (event: any) => {
            // read the value of the characteristic now--the device will have
            // updated it as its "response"
            cmdChar.stopNotifications();
            logHex(event.target.value, 'RSP: ');
            resolve(event.target.value);
          },
        );
      });
      cmdChar.writeValueWithoutResponse(cmd);

      return notificationPromise;
    }
    cmdChar.writeValueWithoutResponse(cmd);
  }

  async _getDataCharacteristic() {
    if (this._data_characteristic === undefined) {
      // get the instance of the characteristic
      const server = await this._server;
      const dataService = await server.getPrimaryService(tatchservices.data.uuidFull);
      this._data_characteristic = await dataService.getCharacteristic(tatchservices.data.characteristics.data);

      // set up the notifications callback, but don't enable notifications yet
      this._data_characteristic.addEventListener('characteristicvaluechanged', this.handleDataArrived);
    }

    return this._data_characteristic;
  }

  async _getCommandCharacteristic(): Promise<BluetoothRemoteGATTCharacteristic> {
    if (this._command_characteristic === undefined) {
      // get the instance of the characteristic
      const server = await this._server;
      const cmdService = await server.getPrimaryService(tatchservices.command.uuidFull);
      this._command_characteristic = await cmdService.getCharacteristic(tatchservices.command.characteristics.command);
    }

    return this._command_characteristic;
  }

  async saveSessionToFile() {
    let csvRows: any = [];
    if (this.session?.packets) {
      this.session.packets.forEach((rowArray: any) => {
        let row = rowArray.join(',');
        csvRows.push(row);
      });
    }
    else {
      console.log('No session data received. Nothing to save.');
    }

    // save a fake metadata
    const fakeMetadata = {
      sessionStartTime: '2020.05.07_00-00-00',
      userData: {
        country: '+1',
      },
      patches: {
        'thorax-template': {
          macAddr: 'MACTHX',
          firmwareRevision: 'N/A',
          hardwareRevision: 'N/A',
          systemId: 'N/A',
          rawFileName: 'KITID_MACTHX_THX.txt',
          bodyLocation: 'THX',
        },
      },
      phoneUniqueId: 'PHONEID',
      timezone: 'America/New_York',
      timezoneDelta: -4,
      appVersion: 'N/A',
      buildNumber: 'N/A',
      s3FolderName: 'N/A',
      phoneDataFileName: 'KITID_PHONEID_PHONE.txt',
    };

    // now save both of these as files in a ZIP archive
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    let zip = require('jszip')();
    zip.file('KITID_metadata.json', JSON.stringify(fakeMetadata));
    zip.file('KITID_MACTHX_THX.txt', csvRows.join('\r\n'));
    let zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, 'tatchconnect_session.zip');
  }
}

import axios from 'axios';

import { User } from 'src/store/modules/userSlice';
import { jwtStorageName, userRoles } from 'src/utils/constants';

import { getUserInfoFromToken, parseJwt } from './jwtToken';

export const loginRequest = async (email: string, password: string) => {
  const based64Credentials = btoa(`${email}:${password}`);
  const link = `${process.env.REACT_APP_API}/signin`;
  const headers = {
    Authorization: `Basic ${based64Credentials}`,
  };
  const response = await axios.post(link, {}, { headers });

  return response;
};

export const resetPasswordRequest = async (email?: string) => {
  if (!email) {
    const token = localStorage.getItem(jwtStorageName);
    if (!token) return;
    email = parseJwt(token).email;
  }

  const link = `${process.env.REACT_APP_API}/reset-password`;
  const response = await axios.post(link, { email });

  return response;
};

export const isCurrentUserAdmin = (currentUser: User) => {
  if (currentUser?.groups && currentUser.groups.includes(userRoles.admin)) {
    const token = localStorage.getItem(jwtStorageName);

    const userInfo = getUserInfoFromToken(token || '');
    if (userInfo.groups.includes(userRoles.admin)) return true;
  }

  return false;
};

export const isUserAllowed = (requiredRole: string) => {
  const token = localStorage.getItem(jwtStorageName);

  const userInfo = getUserInfoFromToken(token || '');
  if (userInfo.groups.includes(requiredRole)) return true;
  if (userInfo.groups.includes(userRoles.admin)) return true;

  return false;
};

import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import ButtonMui from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabelMui from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import styled from 'styled-components';

import { Input, KitGroupResponseModal } from 'src/components';
import { useMutationKitForm } from 'src/hooks/useMutationKitGroup';
import { PatchData, PatchFormProps } from 'src/types';
import { colors } from 'src/utils/colors';
import { patchesIdNames, patchesIdTypes } from 'src/utils/constants';

const FIRMWARE_VERSIONS = ['1.3.0', '2.0.7', '2.3.0'];
const BATCH_DATES = ['20220304', '20220112'];

const FormControlLabel = styled(FormControlLabelMui)`
  width: 100%;
  margin-left: 1rem;
`;

const Button = styled(ButtonMui)`
  background: ${colors.lightGray};
  font-weight: 'normal';
  border-radius: 0px;
  color: ${colors.darkGray};
  &:hover {
    background: ${colors.lightGrayHover};
  }
`;

const patchInitialState: PatchData = {
  batchDate: BATCH_DATES[1],
  macAddress: '',
  idType: patchesIdTypes.serialNumber,
  providedId: '',
  firmwareVersion: '2.3.0',
  recycledFlag: false,
  isBatteryOverMin: false, // min is 4V for now (May 2022)
};

const patchesInitialData: [PatchData, PatchData] = [
  { ...patchInitialState },
  { ...patchInitialState },
];


type UpdatePatchProps = {
  param: string;
  index: number;
  value: boolean | Date | string | null | number;
}

export const KitGroupForm = () => {
  const [kitSerialNumber, setKitSerialNumber] = useState<string>('');
  const [groupedAt, setGroupedAt] = useState<Date | null>(new Date());
  const [resetFlag, setResetFlag] = useState(false);
  const [formattedFlag, setFormattedFlag] = useState(false);
  const [patches, setPatches] = useState(patchesInitialData);

  const updatePatches = ({ param, index, value }: UpdatePatchProps) => {
    const newPatches: [PatchData, PatchData] = [{ ...patches[0] }, { ...patches[1] }];
    const patchToChange = newPatches[index];
    if (
      param === 'macAddress'
      && typeof value === 'string'
      && value.length > 4
    ) return;
    patchToChange[param] = value;
    newPatches[index] = patchToChange;
    setPatches(newPatches);
  };

  const { data, createKitGroup, isLoading, isSuccess } = useMutationKitForm();

  const sendKitGroupForm = () => {
    if (!groupedAt || !kitSerialNumber) return;

    const finalPatches: PatchFormProps[] = patches.map(
      ({
        isBatteryOverMin,
        providedId,
        idType,
        macAddress,
        firmwareVersion,
        batchDate,
        recycledFlag,
      }) => {
        const patchParams = {
          idType,
          providedId,
          macAddress,
          battery: isBatteryOverMin ? 4.1 : 0,
          firmwareVersion,
          recycledFlag,
        };

        if (idType === patchesIdTypes.serialNumber) {
          // let patchId = providedId;
          // if (patchId?.length === 1) patchId = `0000${patchId}`;
          // if (patchId?.length === 2) patchId = `000${patchId}`;
          // if (patchId?.length === 3) patchId = `00${patchId}`;
          // if (patchId?.length === 4) patchId = `0${patchId}`;
          // patchParams.providedId = `WSP${batchDate}_${patchId}`;
          patchParams.providedId = providedId;
        }

        return patchParams;
      });

    const kitGroupFormParams = {
      serialNumber: kitSerialNumber,
      groupedAt: format(groupedAt, 'LL-dd-yyyy'),
      resetFlag,
      formattedFlag,
      patches: finalPatches,
    };
    createKitGroup(kitGroupFormParams);
  };

  const shouldDisableSaveBtn = () => {
    if (
      !groupedAt
      || !kitSerialNumber.length
      || !resetFlag
      || !formattedFlag
      || !patches[0].providedId?.length
      || !patches[1].providedId?.length
      || !patches[0].macAddress?.length
      || !patches[1].macAddress?.length
    ) return true;
  };

  const clearForm = () => {
    setKitSerialNumber('');
    setGroupedAt(new Date());
    setResetFlag(false);
    setFormattedFlag(false);
    setPatches(patchesInitialData);
  };

  return (
    <div style={{ width: '95%', margin: '30px auto' }}>
      <KitGroupResponseModal
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
      <Typography variant="h3">Kit Group Form</Typography>
      <Card sx={{ padding: '20px' }}>
        <form>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-end">
              {patches.map((patch, index) => (
                <Grid item xs={12} sm={6} key={`patch-${index}`}>
                  <Card sx={{ padding: '20px' }}>
                    <Typography variant="h5" align="left">{`Patch ${index + 1}`}</Typography>
                    <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-end">
                      <Grid item xs={12} sm={6}>
                        <ToggleButtonGroup
                          color="primary"
                          value={patch.idType}
                          exclusive
                          onChange={(event, newAlignment: string) => updatePatches(
                            { param: 'idType', index, value: newAlignment }
                          )}
                        >
                          <ToggleButton value={patchesIdTypes.factoryId}>{patchesIdNames.factoryId}</ToggleButton>
                          <ToggleButton value={patchesIdTypes.serialNumber}>{patchesIdNames.serialNumber}</ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Input
                          autoComplete="off"
                          label={
                            patch.idType === patchesIdTypes.factoryId
                              ? patchesIdNames.factoryId : patchesIdNames.serialNumber
                          }
                          variant="outlined"
                          value={patch.providedId}
                          onChange={
                            (event) => updatePatches({ param: 'providedId', index, value: event?.target?.value })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={patch.isBatteryOverMin}
                              onChange={
                                (
                                  event: React.ChangeEvent<HTMLInputElement>,
                                  checked: boolean
                                ) => updatePatches({ param: 'isBatteryOverMin', index, value: checked })
                              }
                              name={`patch${index}.isBatteryOverMin`}
                            />
                          }
                          label="Battery over 4V"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Divider sx={{ margin: '20px 0 10px' }} />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id={`patch-${index}-batch-date`}>Batch date</InputLabel>
                          <Select
                            labelId={`patch-${index}-batch-date-label`}
                            id={`patch-${index}-batch-date-select-id`}
                            value={patch.batchDate}
                            label="Batch date"
                            onChange={
                              (event) => updatePatches(
                                { param: 'batchDate', index, value: event?.target?.value }
                              )
                            }
                          >
                            {BATCH_DATES.map((batchDate) => (
                              <MenuItem value={batchDate} key={batchDate}>{batchDate}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Input
                          autoComplete="off"
                          label="Mac Address"
                          variant="outlined"
                          value={patch.macAddress}
                          onChange={
                            (event) => updatePatches({ param: 'macAddress', index, value: event?.target?.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id={`patch-${index}-firmware-version`}>Firmware Version</InputLabel>
                          <Select
                            labelId={`patch-${index}-firmware-version-label`}
                            id={`patch-${index}-firmware-version-select-id`}
                            value={patch.firmwareVersion}
                            label="Firmware Version"
                            onChange={
                              (event) => updatePatches(
                                { param: 'firmwareVersion', index, value: event?.target?.value }
                              )
                            }
                          >
                            {FIRMWARE_VERSIONS.map((item: string) => (
                              <MenuItem value={item} key={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={patch.recycledFlag}
                              onChange={
                                (
                                  event: React.ChangeEvent<HTMLInputElement>,
                                  checked: boolean
                                ) => updatePatches({ param: 'recycledFlag', index, value: checked })
                              }
                              name={`patch${index}.recycledFlag`}
                            />
                          }
                          label="Recycled"
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )) }

              <Grid item xs={12}>
                <Typography variant="h5" align="left">Kit</Typography>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Input
                  autoComplete="off"
                  label="Kit Serial Number"
                  variant="outlined"
                  value={kitSerialNumber}
                  onChange={(event) => setKitSerialNumber(event?.target?.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="MM/dd/yyyy"
                  value={groupedAt}
                  onChange={(newDate: Date | null) => setGroupedAt(newDate)}
                  renderInput={(params) => <TextField sx={{ width: '85%' }} {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={resetFlag}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setResetFlag(checked)}
                      name="resetFlag"
                    />
                  }
                  label="Reset"
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formattedFlag}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setFormattedFlag(checked)}
                      name="resetFlag"
                    />
                  }
                  label="Format Storage"
                />
              </Grid>
            </Grid>

          </LocalizationProvider>
          <Grid container justifyContent="space-between" marginTop="50px">
            <Grid item xs="auto">
              <Button
                variant="contained"
                onClick={() => clearForm()}
              >
              Clear Form
              </Button>
            </Grid>
            <Grid item xs="auto">
              <LoadingButton
                disabled={shouldDisableSaveBtn()}
                variant="contained"
                loading={isLoading}
                onClick={() => sendKitGroupForm()}
              >
              Save
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Card>
    </div>
  );
};

import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EventLog } from 'src/types';
import { axiosGet, axiosPost } from 'src/utils/apiRequests';
import { API_PATHS, loadStatus } from 'src/utils/constants';
import { normalizeById } from 'src/utils/sessions';

type Props = {
  status: string;
  data: any;
  error: any;
  totalSessions: number;
  selectedSessionEvents: EventLog[] | [];
  eventsRequestStatus: string;
  eventTypes: { [key: string]: string };
  eventsError: any;
}

const initialState: Props = {
  status: loadStatus.neverLoaded,
  data: [],
  error: false,
  totalSessions: 0,
  selectedSessionEvents: [],
  eventsRequestStatus: loadStatus.neverLoaded,
  eventTypes: {},
  eventsError: false,
};

type FetchSessionsProps = {
  page: number;
  itemsPerPage: number;
  kitId?: string;
  shouldLoadUser?: boolean;
}

const fetchSessionsRequest = async (params: FetchSessionsProps) => {
  const { page, itemsPerPage, kitId = '' } = params;
  const link = `${process.env.REACT_APP_API}/sessions`;
  const configParams: FetchSessionsProps = {
    page, itemsPerPage, kitId, shouldLoadUser: true,
  };

  // API doesn't accept empty kit id
  if (!kitId.length) delete configParams.kitId;

  const data = await axiosGet({ link, config: { params: configParams } });

  return { data, page };
};

export const fetchSessions = createAsyncThunk(
  'sessions/fetchSessions',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: FetchSessionsProps, thunkAPI) => {
    const data = await fetchSessionsRequest(params);

    return data;
  },
);

type FetchSessionByIdProps = {
  sessionId: string;
}
export const fetchSessionById = createAsyncThunk(
  'session/fetchSessionById',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: FetchSessionByIdProps, thunkAPI) => {
    const { sessionId } = params;
    const link = `${process.env.REACT_APP_API}/sessions/${sessionId}`;
    const config = { params: { sessionId } };
    const data = await axiosGet({ link, config });

    return { data };
  },
);

type FetchEventsProps = {
  token: string;
  sessionId: string;
  loadEventTypes: string;
}

export const fetchSessionEvents = createAsyncThunk(
  'sessions/fetchEvents',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: FetchEventsProps, thunkAPI) => {
    const { sessionId, loadEventTypes = 'False' } = params;
    const link = `${process.env.REACT_APP_API}/events`;
    const config = { params: { sessionId, loadEventTypes } };
    const data = await axiosGet({ link, config });

    return { data };
  },
);

type PostReprocessProps = {
  // either the UUID for the user or the session to reprocess
  uuid: string;
  // if true, this is a user ID. if false, it's a session ID
  isUser: boolean;
  isSilent: boolean
}

type SessionDetailPros = {
  sessionId: string;
}


type ProcessingRequest = {
  sessionId?: string;
  userId?: string;
  isSilent?: boolean;
}

type ProcessingRequestResponse = {
  sessionId?: string;
  userId?: string;
  isSilent?: boolean;
  didSucceed: boolean;
}

type PostReprocessBody = {
  sessionProcessRequests: Array<ProcessingRequest>;
}

export const postReprocess = createAsyncThunk(
  'sessions/reprocess',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: PostReprocessProps, thunkAPI) => {
    const { uuid, isUser, isSilent } = params;
    const link = `${process.env.REACT_APP_API}${API_PATHS.SESSION_REPROCESS}`;
    const body: PostReprocessBody = {
      sessionProcessRequests: [
        {
          isSilent: isSilent,
        },
      ],
    };
    if (isUser) {
      body.sessionProcessRequests[0].userId = uuid;
    } else {
      body.sessionProcessRequests[0].sessionId = uuid;
    }

    const data = await axiosPost({ link, params: body });

    return { data };
  },
);

export const getSessionDetails = async (props: SessionDetailPros) => {

  const link = `${process.env.REACT_APP_API}${API_PATHS.SESSION_DETAILS}/${props.sessionId.toUpperCase()}`;
  const { sessionId } = props;
  const config = { params: { sessionId } };
  const response = await axiosGet({ link, config });

  return response;
};

const sessionsSlice = createSlice({
  name: 'sessionsSlice',
  initialState,
  reducers: {
    setSessions: (state) => state,
    updateSelectedSessionEvents(state, { payload }: PayloadAction<EventLog[]>) {
      state.selectedSessionEvents = payload;
    },
  },
  extraReducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [fetchSessions.pending.type]: (state, action) => {
      state.status = loadStatus.loading;
    },
    [fetchSessions.fulfilled.type]: (state, { payload }) => {
      state.status = loadStatus.success;
      state.error = false;
      // next page will only add sessions to the list
      if (payload.page > 1) state.data.push(...payload.data.sessions);

      if (payload.page === 1) {
        state.data = payload.data.sessions;
        state.totalSessions = payload.data.total;
      }
    },
    [fetchSessions.rejected.type]: (state, action) => {
      const message =
        action?.error?.message
        || 'Fail to fetch data from GET /sessions (check network logs for more info';
      state.error = message;
      state.status = loadStatus.failed;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [fetchSessionById.pending.type]: (state, action) => {
      state.status = loadStatus.loading;
    },
    [fetchSessionById.fulfilled.type]: (state, { payload }) => {
      state.status = loadStatus.success;
      state.error = false;
      state.data = [payload.data.session];
      state.totalSessions = 1;
    },
    [fetchSessionById.rejected.type]: (state, action) => {
      const message = action?.error?.message || 'Request to search Sessions by ID failed.';
      state.error = message;
      state.status = loadStatus.failed;
    },
    [postReprocess.fulfilled.type]: (state, { payload }) => {
      const results: Array<ProcessingRequestResponse> = payload.data.sessionProcessRequests;
      const nSuccess = results.filter((result) => {
        return result.didSucceed;
      }).length;
      const nTotal = results.length;
      const msg = 'Retriggered ' + nSuccess + '/' + nTotal + ' sessions.';
      if (nTotal == nSuccess) {
        toast.success(msg, { autoClose: 3000 });
      }
      else {
        toast.error(msg, { autoClose: 3000 });
      }
    },
    [postReprocess.rejected.type]: (state, action) => {
      const message = action?.error?.message
        || 'Failed to trigger reprocessing with POST /sessions/reprocess. (See network logs)';
      state.error = message;
      state.status = 'Failed';
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [fetchSessionEvents.pending.type]: (state, action) => {
      state.eventsRequestStatus = loadStatus.loading;
    },
    [fetchSessionEvents.fulfilled.type]: (state, { payload }) => {
      state.selectedSessionEvents = payload.data.events;
      state.eventsRequestStatus = loadStatus.success;

      // Normalize event types to make the search easier
      if (payload.data?.event_types?.length) {
        const eventTypesNormalized: any = normalizeById(payload.data.event_types);
        state.eventTypes = eventTypesNormalized;
      }
    },
    [fetchSessionEvents.rejected.type]: (state, action) => {
      const message =
        action?.error?.message
        || 'Fail to fetch data from GET /events  (check network logs for more info';
      state.eventsError = message;
      state.eventsRequestStatus = loadStatus.failed;
    },
  },
});

const sessionsActions = sessionsSlice.actions;

export { sessionsActions };

export default sessionsSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  name?: string;
  email?: string;
  groups: string[];
}

type Props = {
  currentUser: User | null;
  checkedLoginOnLoad: boolean;
}

export const initialState: Props = {
  currentUser: null,
  checkedLoginOnLoad: false,
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }: PayloadAction<User>) => {
      state.currentUser = payload;
    },
    setCheckedLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.checkedLoginOnLoad = payload;
    },
    logout: (state) => {
      state.currentUser = null;
      state.checkedLoginOnLoad = true;
    },
  },
});

const userActions = userSlice.actions;

export { userActions };

export default userSlice.reducer;

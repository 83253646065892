import { Session } from 'src/types';

export const getStatusColor = (status: string | null) => {
  if (status && status.includes('error')) return '#FF7979';
  if (status && status.includes('waiting')) return '#f0ed32';

  return '#2bab4d';
};

export const humanizeEventStatus = (status: string) => {
  const finalStatus = status.replace('_', ' ');

  return finalStatus.charAt(0).toUpperCase() + finalStatus.slice(1);
};


export const normalizeById = (objects: Array<any>) => {
  const objectsNormalized: any = {};
  objects.map((item: any) => {
    objectsNormalized[item.id] = item;
  });

  return objectsNormalized;
};

const sessionTypePatches = {
  one_component: ['fIN'],
  two_component: ['tHX', 'aBD'],
  three_component: ['tHX', 'aBD', 'fIN'],
  three_component_legs_pulseox: ['lLG', 'rLG', 'fIN'],
};

export const getPatchOrder = (session: Session) => {
  if (Object.keys(sessionTypePatches).includes(session.sessionType)) {
    return sessionTypePatches[session.sessionType];
  }

  return [];
};

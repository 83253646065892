import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import IconButtonMui from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainerMui from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRowMui from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

import { xIcon } from 'src/assets/images';
import { SessionModal } from 'src/components';
import { useTypedSelector } from 'src/hooks';
import { fetchSessionById, fetchSessions } from 'src/store/modules/sessionsSlice';
import { Session } from 'src/types';
import { colors } from 'src/utils/colors';
import { uuidV4Validation } from 'src/utils/common';
import { dateColumns, loadStatus } from 'src/utils/constants';
import { formatMysqlDate } from 'src/utils/date';
import { getStatusColor } from 'src/utils/sessions';

const columns = [
  { id: 'kitId', label: 'Kit ID' },
  { id: 'userTimezone', label: 'TZ' },
  { id: 'startedAt', label: 'Start' },
  { id: 'endedAt', label: 'End' },
  { id: 'updatedAt', label: 'Updated At' },
  { id: 'currentStatus', label: 'Status' },
];

type ContainerProps = {
  opacity: string;
}

const Container = styled.div <ContainerProps>`
  width: 90%;
  min-width: 1000px;
  max-width: 100%;
  padding: 32px 16px;
  margin: 0 auto;
  opacity: ${props => props.opacity};
`;

const RowHeader = styled(TableRowMui)`
  background: ${colors.lightGray};
`;

const TableContainer = styled(TableContainerMui)`
  max-height: 65vh;
`;

const TableCellHeader = styled(TableCell)`
  font-size: 0.9rem;
  font-weight: bold;
`;

const TableRow = styled(TableRowMui)`
  cursor: pointer;
`;

const Actions = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

type InputProps = {
  $minWidth: string;
}
const Input = styled(TextField)<InputProps>`
  min-width: ${props => props.$minWidth};
`;

const IconButton = styled(IconButtonMui)`
`;

const StatusCell = styled(TableCell)`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`;

const StatusDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #cecece75;
`;

export const Sessions = () => {
  const [sessions, setSessions] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [kitId, setKitId] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedSession, setSelectedSession] = useState<null | Session>(null);

  const tableRef = useRef<any>(null);

  const {
    status,
    data: sessionsData,
    totalSessions,
    error: sessionFetchError,
  } = useTypedSelector(({ sessions }) => sessions);

  const dispatch: any = useDispatch();

  const loadSessions = (pageNumber: number, kit = kitId) => {
    if (status === loadStatus.loading) return;

    dispatch(fetchSessions({ page: pageNumber, itemsPerPage, kitId: kit }));
  };

  const setSessionsByPage = (pageNumber: number) => {
    const firstSession = pageNumber * itemsPerPage;
    if (firstSession < 0 || !sessionsData || !sessionsData.length) return;

    setSessions(
      sessionsData.slice(firstSession, firstSession + itemsPerPage),
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    if (newPage > page) loadSessions(newPage + 1);

    if (newPage < page) setSessionsByPage(newPage);
    if (tableRef?.current) tableRef.current.scrollTop = 0;
  };

  const handleChangeSessionsPerPage = (event: any) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openSessionModal = (session: Session) => {
    setSelectedSession(session);
    setOpenModal(true);
  };

  const resetSearch = () => {
    setKitId('');
    setSessionId('');
    setPage(0);
    loadSessions(1, '');
  };

  // Called when the page load
  useEffect(() => {
    // on server the page must start at 1 and no 0 like here
    loadSessions(page + 1);
  }, [itemsPerPage]);

  // Define the sessons list when sessionsData changes
  useEffect(() => {
    setSessionsByPage(page);
  }, [sessionsData]);

  useEffect(() => {
    if (!kitId.length) {
      resetSearch();

      return;
    }

    if (kitId.length < 3) return;

    const debounceSearchRequest = setTimeout(() => {
      setPage(0);
      loadSessions(1);
    }, 1000);

    return () => clearTimeout(debounceSearchRequest);
  }, [kitId]);

  useEffect(() => {
    if (!!uuidV4Validation(sessionId)) {
      dispatch(fetchSessionById({ sessionId }));
    }
  }, [sessionId]);

  useEffect(() => {
    if (status === loadStatus.failed && sessionFetchError) {
      toast.error(sessionFetchError, { autoClose: 15000 });
    }
  }, [status]);

  const isSessionIdValid = () => {
    if (!sessionId.length) return true;

    return uuidV4Validation(sessionId);
  };

  return (
    <Container opacity={status === loadStatus.loading ? '0.1' : '1'}>
      <SessionModal open={openModal} setOpen={setOpenModal} session={selectedSession} />
      <Actions>
        <Grid container justifyContent="flex-start" spacing={3}>
          <Grid item>
            <Input
              $minWidth="200px"
              size="small"
              variant="outlined"
              label="Kit ID"
              value={kitId}
              onChange={(event) => setKitId(event?.target?.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" aria-label="x-clear" onClick={resetSearch}>
                      <img src={xIcon} alt="x-icon" width="25" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Input
              $minWidth="380px"
              size="small"
              variant="outlined"
              label="Session ID"
              value={sessionId}
              onChange={(event) => setSessionId(event?.target?.value)}
              placeholder="Search by Session full ID"
              error={!isSessionIdValid()}
              helperText={isSessionIdValid() ? '' : 'ID must be a valid uuid, ex: 9443b0d9-f011-4bae-85b8-f01b57933fbe'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" aria-label="x-clear" onClick={resetSearch}>
                      <img src={xIcon} alt="x-icon" width="25" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Actions>

      <Paper>
        <TableContainer ref={tableRef}>
          <Table stickyHeader>
            <TableHead>
              <RowHeader>
                {columns.map(column => (
                  <TableCellHeader key={column.id}>
                    {column.label}
                  </TableCellHeader>
                ))}
              </RowHeader>
            </TableHead>

            <TableBody>
              {sessions.map((session: any) => (
                <TableRow hover key={session.id} onClick={() => openSessionModal(session)}>
                  {columns.map(column => {
                    if (column.id === 'currentStatus' && session.currentStatus) {
                      return (
                        <StatusCell key={column.id}>
                          <StatusDot style={{ background: getStatusColor(session.currentStatus) }} />
                          {session.currentStatus}
                        </StatusCell>
                      );
                    }

                    const value = dateColumns.includes(column.id)
                      ? formatMysqlDate(session[column.id]) : session[column.id];

                    return (
                      <TableCell key={column.id}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={totalSessions}
          rowsPerPage={itemsPerPage}
          page={page} // TablePagination starts to count page from 0
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeSessionsPerPage}
        />
      </Paper>
    </Container>
  );
};

import TextField from '@mui/material/TextField';
import styled from 'styled-components';

import { colors } from 'src/utils/colors';

export const Input = styled(TextField)`
  width: 90%;
  margin-top: 20px !important;
  & label.Mui-focused {
    color: ${colors.darkGray};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${colors.darkGray};
    }
  }
`;

import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContentMui from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import { successCheckIcon } from 'src/assets/images';

const DialogContent = styled(DialogContentMui)`
  padding: 0px 30px 30px;
`;

const IconContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  height: 40px;
`;

type Props = {
  data: any;
  isSuccess: boolean;
  isLoading: boolean;
}

export const KitGroupResponseModal = ({ data, isSuccess, isLoading }: Props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isLoading && isSuccess && !open && data) setOpen(true);
  }, [isSuccess, isLoading]);

  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ minHeight: '300px', padding: '2rem' }}>
        <IconContainer>
          <img src={successCheckIcon} alt="success-check-icon" width="35" style={{ float: 'right' }} />
        </IconContainer>
        <Typography variant="body1">
          Kit with <b>serial number: {data?.kit.serialNumber}</b> was created.
        </Typography>
        <Typography variant="body1" marginTop="20px">
          Patch 1 with <b>{data?.patches[0].idType}: {data?.patches[0].providedId}</b> was associated with the kit.
        </Typography>
        <Typography variant="body1" marginTop="20px">
          Patch 2 with <b>{data?.patches[1].idType}: {data?.patches[1].providedId}</b> was associated with the kit.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

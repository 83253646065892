import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import ButtonMui from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ToolbarMui from '@mui/material/Toolbar';
import TypographyMui from '@mui/material/Typography';
import styled from 'styled-components';

import { wesperLogo } from 'src/assets/images';
import { useTypedSelector } from 'src/hooks';
import { fetchSessions } from 'src/store/modules/sessionsSlice';
import { isUserAllowed } from 'src/utils/auth';
import { colors } from 'src/utils/colors';
import { routes, userRoles } from 'src/utils/constants';

import { SettingsMenu } from './SettingsMenu';

const TatchLogo = styled.img`
  width: 160px;
  cursor: pointer;
`;

const Typography = styled(TypographyMui)`
  color: #000000;
  text-transform: capitalize;
`;

type BtnProps = {
  background?: string;
  fontWeight?: string;
}

const Button = styled(ButtonMui) <BtnProps>`
  min-width: 95px;
  padding: 12px 18px;
  font-size: 0.8rem;
  letter-spacing: 1.5px;
  background: ${props => props.background || '#f2f2f2'};
  font-weight: ${props => props.fontWeight || 'normal'};
  border-radius: 0px;
  color: ${colors.darkGray};
`;

const Toolbar = styled(ToolbarMui)`
  min-height: 50px;
`;

export const Header = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const location = useLocation();

  const { currentUser } = useTypedSelector(({ user }) => user);

  const navigateRoute = (path: string) => {
    if (location.pathname === path && path === routes.home) {
      dispatch(fetchSessions({ page: 1, itemsPerPage: 10, kitId: '' }));

      return;
    }

    navigate(path);
  };

  const navLink = (path: string, name: string) => (
    <Grid item>
      <Button
        onClick={() => navigateRoute(path)}
        background={location.pathname === path ? '#ffffff' : '#f2f2f2'}
        fontWeight={location.pathname === path ? 'bold' : 'normal'}
      >
        {name}
      </Button>
    </Grid>
  );

  return (
    <AppBar position="static" style={{ background: colors.lightGray }}>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <TatchLogo src={wesperLogo} onClick={() => navigate(routes.home)} />
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item style={{ marginRight: '20px' }}>
                <Typography variant="body1">{currentUser?.name}</Typography>
              </Grid>
              { isUserAllowed(userRoles.admin) &&
                <>{navLink(routes.home, 'Sessions')}</>
              }
              { isUserAllowed(userRoles.hardware) &&
                <>{navLink(routes.wesperMon, 'Wesper Mon')}</>
              }
              { isUserAllowed(userRoles.manufacturer) &&
                <>{navLink(routes.kitGroupForm, 'Kit Group Form')}</>
              }
              <Grid item>
                <SettingsMenu />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

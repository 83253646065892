/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonMui from '@mui/material/Button';
import styled from 'styled-components';

import { ITatchSpO2Data } from 'src/utils/bleCustomServices';
import { buf2hex, DeviceInfo } from 'src/utils/bleHelper';
import { colors } from 'src/utils/colors';
import {
  connectToPatch, PatchManager, RecoverySession,
} from 'src/utils/patchManager';

const Container = styled.div`
  width: 90%;
  margin: 50px auto 0px;
`;

const Button = styled(ButtonMui)`
  background: #ffffff;
  letter-spacing: 2px;
  &:hover {
    background: #f0f0f0;
  }
  color: ${colors.darkGray}
`;

interface IProps {
  [key: string]: any;
}

interface IState {
  [key: string]: any;
}

interface ITatchMonState {
  devices: {[id: string]: PatchManager}
}

interface IPatchInterfaceProps {
  key: string;
  manager: PatchManager;
  unmountHandler: (id: string) => void;
}

/**
 * Component for the interface to a single patch.
 *
 * @class      PatchInterface (name)
 */
class PatchInterface extends React.Component<IPatchInterfaceProps, IState> {
  constructor(props: IPatchInterfaceProps) {
    super(props);
    this.state = {
      name: this.props.manager.name,
      status: 'Patch inactive.',
      deviceState: 'loading...',
      error: 'None',
      sessionIsActive: false,
      sessionStartTime: null,
      spo2Data: null,
      commandResponse: 'None',
    };

    this.onDisconnectPatch = this.onDisconnectPatch.bind(this);
    this.onPowerOffPatch = this.onPowerOffPatch.bind(this);
    this.onResetPatch = this.onResetPatch.bind(this);
    this.onStartSession = this.onStartSession.bind(this);
    this.onStopSession = this.onStopSession.bind(this);
    this.onGetPatchState = this.onGetPatchState.bind(this);
    this.onGetError = this.onGetError.bind(this);
    this.onFormatStorage = this.onFormatStorage.bind(this);
    this.onSpamCommand = this.onSpamCommand.bind(this);
    this.onRecoverCatchUp = this.onRecoverCatchUp.bind(this);
    this.onCommandSend = this.onCommandSend.bind(this);
  }

  componentDidMount() {
    this.onGetPatchState();
  }

  render() {
    const dataDisplay = (this.props.manager._spo2_service) ?
      <LiveSpO2View data={this.state.spo2Data}></LiveSpO2View> :
      <div>Live HR/SpO2 not available</div>;

    return (
      <div className="PatchInterface">
        <div>{this.state.name}</div>
        <DeviceInfoView patchManager={this.props.manager}/>
        <button onClick={this.onDisconnectPatch}>Disconnect from Patch</button>
        <button onClick={this.onPowerOffPatch}>Power Off & Disconnect</button>
        <button onClick={this.onResetPatch}>Reset</button>
        <button onClick={this.onStartSession}>Start Session</button>
        <button onClick={this.onStopSession}>Stop Session</button>
        <button onClick={this.onGetPatchState}>Refresh Device State</button>
        <button onClick={this.onGetError}>Get Error</button>
        <button onClick={this.onFormatStorage}>Format Storage</button>
        <button onClick={this.onSpamCommand}>Spam Commands</button>
        <button onClick={this.onRecoverCatchUp}>Recover from Crash</button>
        <div>Status message: {this.state.status}</div>
        <div>Device State: {this.state.deviceState}</div>
        <div>Error: {this.state.error}</div>
        {dataDisplay}
        <CommandSender onCommandSend={this.onCommandSend} response={this.state.commandResponse}/>
        <SessionTimer startTime={this.state.sessionStartTime} isCounting={this.state.sessionIsActive}/>
      </div>
    );
  }

  onCommandSend(cmd: Uint8Array): void {
    this.props.manager._sendCommand(cmd, true)
      .then((val: DataView | undefined ) => {
        if (val != undefined ) {
          this.setState({ commandResponse: buf2hex(val.buffer) });
        }
      });
  }

  onSpamCommand() {
    this.setStatus('Spamming commands...');
    this.props.manager.spamCommand()
      .then((_: any) => {
        this.setStatus('Commands sent...');
      });
  }

  onStartSession() {
    this.setStatus('Starting session...');
    this.props.manager.startSession((data) => {
      this.setState({
        spo2Data: data,
      });
    })
    	.catch(err => alert(err))
      .then((_: any) => {
        this.setState({
          sessionStartTime: new Date(),
          sessionIsActive: true,
        });
        this.setStatus('Session in progress...');
      });
  }

  onStopSession() {
    this.setStatus('Stopping session...');
    this.props.manager.stopSession()
      .then((_: any) => {
        this.setState({ sessionIsActive: false });
        this.setStatus('Patch inactive.');
      });
  }

  onGetPatchState() {
    this.props.manager.getDeviceState()
      .then((info: any) => {
        let str = '';
        if (info.success) {
          str += `${info.stateString} (${info.state}).`;
          str += ` ${info.remainingPages} pages unsent. Battery voltage: ${info.batteryVoltage / 1000}V.`;
        }
        else {
          str += 'GetDeviceState unsuccessful.';
        }
        str += ` rawMsg: (${info.rawMsg})`;
        this.setState({ deviceState: str });
      });
  }

  onDisconnectPatch() {
    this.setState({ sessionIsActive: false });
    this.props.manager.disconnect();
    this.setStatus('Disconnecting.');
    this.props.unmountHandler(this.props.manager.id);
  }

  onPowerOffPatch() {
    this.setState({ sessionIsActive: false });
    this.props.manager.powerOff();
    this.props.unmountHandler(this.props.manager.id);
  }

  onResetPatch() {
    this.setState({ sessionIsActive: false });
    this.setStatus('Resetting.');
    this.props.manager.reset();
  }

  onGetError() {
    this.props.manager.getError()
      .then((err: any) => {
        let str = '';
        if (err.isError) {
          const code = err.code.toString(16);
          str = `ERR 0x${code} raised at line ${err.lineNo} in "${err.fileName}" -- (${err.rawMsg})`;
        }
        else {
          str = 'None. Patch is not in error state.';
        }
        this.setState({ error: str });
      });
  }

  onFormatStorage() {
    this.setStatus('Clearing flash storage of session data...');
    this.props.manager.formatStorage()
      .then((_:any) => {
        this.setStatus('Storage cleared.');
      });
  }

  onRecoverCatchUp() {
    this.props.manager.startSessionRecovery((session: RecoverySession) => {
      if (session.error) this.setStatus(session.error);
      else {
        if (session.pagesReceived < session.pagesToRecover) {
          this.setStatus('Recovering page ' + session.pagesReceived + ' of ' +
          session.pagesToRecover + ' pages from device...');
        }
        else {
          this.setStatus('Recovery complete.');
        }
      }
    });
  }


  setStatus(msg: any) {
    this.setState({ status: msg });
  }
}

interface ILiveSpO2Props {
  data: ITatchSpO2Data;
}


function LiveSpO2View(props: ILiveSpO2Props) {
  return (
    <div>Live data: {JSON.stringify(props.data)}</div>
  );
}


interface IDeviceInfoState {
  info: DeviceInfo | null
}

/**
 * Component for the display of the patch's device info
 *
 * @class      DeviceInfo (name)
 */
class DeviceInfoView extends React.Component<{patchManager: PatchManager}, IState> {
  state: IDeviceInfoState;

  constructor(props: any) {
    super(props);
    this.state = { info: null };
  }

  componentDidMount() {
    this.props.patchManager.getDeviceInfo()
      .then((info: DeviceInfo) => {
        console.log(info);
        this.setState({ info: info });
      });
  }

  render() {
    let contents = undefined;
    if (this.state.info === null) {
      contents = <span>"Awaiting device info..."</span>;
    }
    else {
      const infoTable = Object.entries(this.state.info).map((entry) => {
        return (
          <tr style={{ border: 'solid', borderWidth: '1px 0' }} key={entry[0]}>
            <td style={{ textAlign: 'right' }}>{entry[0]}</td>
            <td style={{ textAlign: 'left' }}>{JSON.stringify(entry[1])}</td>
          </tr>
        );
      });

      contents = <table><tbody>{infoTable}</tbody></table>;
    }

    return (
      <div style={
        {
          borderCollapse: 'collapse',
        }
      }>{contents}</div>
    );
  }
}

class SessionTimer extends React.Component<IProps, IState> {
  intervalID: any = null;
  constructor(props: any) {
    super(props);
    this.state = {
      durationMs: 0,
    };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.isCounting !== this.props.isCounting) {
      // we've either started or stopped
      if (this.props.isCounting) {
        this.startTimer();
      }
      else {
        this.pauseTimer();
      }
    }
  }

  componentWillUnmount() {
    if (this.props.isCounting) {
      this.pauseTimer();
    }
  }

  render() {
    return (
      <div className='SessionTimer'>
        <div>Session Duration: {this.msToTime(this.state.durationMs)}</div>
        <div>Session Start Time: {this.props.startTime ? this.props.startTime.toString() : 'N/A'}</div>
      </div>
    );
  }

  startTimer() {
    this.intervalID = setInterval(_ => {
      const newDuration = this.props.startTime ? Math.abs(new Date().valueOf() - this.props.startTime.valueOf()) : 0;
      this.setState((prevState, props) => {
        return { durationMs: props.isCounting ? newDuration : prevState.durationMs };
      });
    }, 1000);
  }

  pauseTimer() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  }

  /**
   * Converts milliseconds duration to hr:min:sec duration
   *
   * @param      {int}  durationMs  The duration in milliseconds
   */
  msToTime(durationMs: any) {
    let seconds: any = Math.floor((durationMs / 1000) % 60);
    let minutes: any = Math.floor((durationMs / (1000 * 60)) % 60);
    let hours: any = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
    const milliseconds = parseInt(durationMs) % 1000 / 100;

    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
  }
}


function CommandSender(props: {onCommandSend: (cmd: Uint8Array) => void, response: string}) {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  function stringToArray(str: string): Uint8Array {
    const byteStrings = str.split(/\s+/);
    let array = new Uint8Array(byteStrings.length);
    for (let i = 0; i < byteStrings.length; i++) {
      array[i] = parseInt(byteStrings[i], 16);
    }

    return array;
  }

  const onSubmit = (data: any) => {
    let array = stringToArray(data.command);
    props.onCommandSend(array);
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* register your input into the hook by invoking the "register" function */}
      <span>Command: </span>
      <input defaultValue="08 00 10"
        {...register('command', {
          pattern: /^(?:[0-9a-fA-F]{2} *)+$/,
          required: true,
        })}
      />
      <input type="submit" />

      {/* errors will return when field validation fails  */}
      <div>
        {errors.command ? 'Command must be a sequence of valid hex bytes separated by spaces (i.e. 08 00 22)' : 'Valid command.'}
      </div>

      <div>
        Response: {props.response}
      </div>
    </form>
  );
}

/**
 * top-level component
 *
 * @class      App (name)
 */
class WesperMon extends React.Component<IProps, ITatchMonState> {
  constructor(props: any) {
    super(props);

    // list of patch BLE devices we've connected to.
    this.state = {
      devices: {},
    };

    this.onConnectButtonPress = this.onConnectButtonPress.bind(this);
    this.handlePatchInterfaceUnmount = this.handlePatchInterfaceUnmount.bind(this);
  }

  onConnectButtonPress(e: any) {
    console.log('Connecting...');
    connectToPatch()
      .then((newPatch: PatchManager) => {
        this.setState((state, props) => {
          const devices = {
            ...state.devices,
            [newPatch.id]: newPatch,
          };

          return { devices: devices };
        });
      });
  }

  handlePatchInterfaceUnmount(managerId: any) {
    this.setState((state) => {
      const devices = {
        ...state.devices,
      };
      delete devices[managerId];

      return { devices: devices };
    });
  }

  render() {
    return (
      <Container>
        <Button variant="contained" onClick={this.onConnectButtonPress}>Connect to Patch</Button>
        {
          Object.values(this.state.devices).map((device: PatchManager) => {
            return (<PatchInterface
              key={device.id} manager={device}
              unmountHandler={this.handlePatchInterfaceUnmount}/>);
          },
          )}
      </Container>
    );
  }
}

export default WesperMon;
